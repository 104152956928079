/* .react-pdf__Page__canvas {
  margin: 0 auto;

  width: 100% !important;
  height: 100% !important;
} */

.customClass > canvas {
  margin: 0 auto;

  /* width: 100% !important;
  height: 100% !important; */
  width: 49vw !important;

  /* width: 64vw !important; */
  height: 65vh !important;
  /* width: 70vw !important;
  height: 72vh !important; */
}

/* @media (max-width: 1404px) {
  .customClass > canvas {
    margin: 0 auto;

    width: 64vw !important;
   
    height: 68vh !important;
  }
}
@media (max-width: 1349px) {
  .customClass > canvas {
    margin: 0 auto;

    width: 64vw !important;
    height: 66vh !important;
  }
}
@media (max-width: 1215px) {
  .customClass > canvas {
    margin: 0 auto;

    width: 64vw !important;
    height: 63vh !important;
  }
}
@media (max-width: 1144px) {
  .customClass > canvas {
    margin: 0 auto;

    width: 64vw !important;
    height: 62vh !important;
  }
} */
/* @media (min-width: 1460px) {
  .customClass > canvas {
    margin: 0 auto;

    width: 55vw !important;

    height: 68vh !important;
  }
}
@media (min-width: 1459px) {
  .customClass > canvas {
    margin: 0 auto;

    width: 55vw !important;

    height: 68vh !important;
  }
} */
@media (min-width: 1659px) and (max-width: 1709px) {
  .customClass > canvas {
    margin: 0 auto;

    width: 47vw !important;
    /* width: 60vw !important; */
    height: 65vh !important;
    /* height: 68vh !important; */
  }
}
@media (min-width: 1609px) and (max-width: 1659px) {
  .customClass > canvas {
    margin: 0 auto;

    width: 48vw !important;
    /* width: 60vw !important; */
    height: 65vh !important;
    /* height: 68vh !important; */
  }
}
@media (min-width: 1559px) and (max-width: 1609px) {
  .customClass > canvas {
    margin: 0 auto;

    width: 50vw !important;
    /* width: 60vw !important; */
    height: 65vh !important;
    /* height: 68vh !important; */
  }
}
@media (min-width: 1509px) and (max-width: 1559px) {
  .customClass > canvas {
    margin: 0 auto;

    width: 51vw !important;
    /* width: 60vw !important; */
    height: 65vh !important;
    /* height: 68vh !important; */
  }
}
@media (min-width: 1459px) and (max-width: 1509px) {
  .customClass > canvas {
    margin: 0 auto;

    width: 52vw !important;
    /* width: 60vw !important; */
    height: 65vh !important;
    /* height: 68vh !important; */
  }
}
@media (min-width: 1409px) and (max-width: 1459px) {
  .customClass > canvas {
    margin: 0 auto;

    width: 52vw !important;
    /* width: 60vw !important; */
    height: 65vh !important;
    /* height: 68vh !important; */
  }
}
@media (min-width: 1359px) and (max-width: 1409px) {
  .customClass > canvas {
    margin: 0 auto;

    width: 54vw !important;
    /* width: 60vw !important; */
    height: 65vh !important;
    /* height: 68vh !important; */
  }
}
@media (min-width: 1308px) and (max-width: 1359px) {
  .customClass > canvas {
    margin: 0 auto;

    width: 55vw !important;
    /* width: 60vw !important; */
    height: 64vh !important;
    /* height: 68vh !important; */
  }
}
@media (min-width: 1257px) and (max-width: 1308px) {
  .customClass > canvas {
    margin: 0 auto;

    width: 55vw !important;
    /* width: 50vw !important; */
    /* width: 60vw !important; */
    height: 63vh !important;
    /* height: 68vh !important; */
  }
}
@media (min-width: 1206px) and (max-width: 1257px) {
  .customClass > canvas {
    margin: 0 auto;

    width: 56vw !important;
    /* width: 60vw !important; */
    height: 62vh !important;
    /* height: 68vh !important; */
  }
}
@media (min-width: 1156px) and (max-width: 1206px) {
  .customClass > canvas {
    margin: 0 auto;

    width: 57vw !important;
    /* width: 60vw !important; */
    height: 61vh !important;
    /* height: 68vh !important; */
  }
}
@media (min-width: 1106px) and (max-width: 1156px) {
  .customClass > canvas {
    margin: 0 auto;

    width: 58vw !important;
    /* width: 60vw !important; */
    height: 60vh !important;
    /* height: 68vh !important; */
  }
}
@media (min-width: 1056px) and (max-width: 1106px) {
  .customClass > canvas {
    margin: 0 auto;

    width: 59vw !important;
    /* width: 60vw !important; */
    height: 59vh !important;
    /* height: 68vh !important; */
  }
}
@media (min-width: 1006px) and (max-width: 1056px) {
  .customClass > canvas {
    margin: 0 auto;

    width: 60vw !important;
    /* width: 60vw !important; */
    height: 59vh !important;
    /* height: 68vh !important; */
  }
}
/* @media (min-width: 1309px) {
  .customClass > canvas {
    margin: 0 auto;

    width: 55vw !important;
   
    height: 68vh !important;
  
  }
} */
/* @media (min-width: 1209px) {
  .customClass > canvas {
    margin: 0 auto;

    width: 50vw !important;
   
    height: 68vh !important;
   
  }
} */
