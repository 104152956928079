#box2 {
  display: flex;
  justify-content: space-between;
}
#typography1 {
  display: inline-flex;
  align-self: center;
}
#typography2 {
  font-size: 12px;
  margin-bottom: 20px;
}
#img {
  width: 65px;
  height: 65px;
}
#icon {
  color: #071aff;
  padding: 15px;
  box-shadow: 0px 1px 6px #00000029;
  border-radius: 5px;
  background-color: white;
}
#gridcontainer1 {
  background-color: #f8f9ff;
  border-radius: 5px;
  margin: 1em 0px;
  padding: 0px 30px;
}
#zeroflowchartgrid {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 1px solid #707070;
  margin-top: 20px;
}
#box1 {
  background-color: #f8f9ff;
  padding: 10px;
  margin: 2.5rem 0px;
}
#box3 {
  overflow-y: auto;
  height: 50%;
  padding: 10px;
  padding-top: 0px;
}
#box3::-webkit-scrollbar {
  width: 5px;
}
#box3::-webkit-scrollbar-track {
  background: #f1f1f1;
}
#box3::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 100px;
}
/* #box3::-webkit-scrollbar-thumb:hover {
  background: #555;
} */
#header {
  top: 0;
  position: sticky;
  background-color: white;
  padding: 0px 30px;
  z-index: 999;
}

#maingrid2 {
  overflow-y: auto;
  height: -webkit-fill-available;
  padding: 0px 60px 0px;
  align-items: center;
  justify-content: space-around;
}
#maingrid2::-webkit-scrollbar {
  width: 5px;
}
#maingrid2::-webkit-scrollbar-track {
  background: #f1f1f1;
}
#maingrid2::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 100px;
}

#gridContainer {
  margin: 2.5rem 0px;
}

/* Upload css */
#uploadbox {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 60%;
  justify-content: space-evenly;
  padding: 50px;
  border: 1px dashed #707070;
  border-radius: 22px;
  cursor: pointer;
  width: 380px;
  position: relative;
}
#uploadgrid {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  height: 70%;
}
#imgicon {
  transform: matrix(0, -1, 1, 0, 0, 0);
  width: 50px;
  margin-left: 40%;
}

#mainGrid {
  padding: 0px 25px 0px;
  /* height: 99%; */
  overflow-y: auto;
  height: 99%;
}

@media (min-height: 628px) and (max-height: 767px) {
  #mainGrid {
    /* padding: 30px 60px 0px; */

    height: 100%;
  }
  #box1 {
    padding: 5px;
  }
  #box3 {
    padding: 0px;

    /* height: 36%; */
  }
}
