#mainBox {
  height: 75vh;
  margin: 39px 74px;
  background-color: #ffffff;
  box-shadow: 0px 3px 16px #00000029;
  border-radius: 5px;
  border: 1px solid #f8f9ff;
  display: flex;
  align-items: center;
  /* overflow-y: auto; */
}

#contentBox {
  width: 100%;
  margin-left: 15px;
  margin-right: 15px;
}

#subContentBox {
  height: 72vh;
  display: flex;
}

#leftTabBox {
  width: 22%;
  background-color: #f8f9ff;
  border-radius: 10px;
  box-shadow: 0px 0px 6px #00000029;
}

#arrowIconBox {
  margin-top: 15px;
  margin-left: 10px;
  color: #071aff;
}

#myProfileButtonBox {
  display: flex;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
  margin-bottom: 12px;
}

#loginSecurityBtnBox {
  display: flex;
  align-items: center;
  margin-top: 12px;
  cursor: pointer;
  margin-bottom: 12px;
}

#rightContentBox {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

#profileAfterClick {
  font-size: 14px;
  font-weight: bold;
  margin-left: 15px;
  color: #071aff;
}

#profileBeforeClick {
  font-size: 14px;
  font-weight: bold;
  margin-left: 15px;
}

#seecurityAfterClick {
  font-size: 14px;
  font-weight: bold;
  margin-left: 15px;
  color: #071aff;
}

#securityBeforeClick {
  font-size: 14px;
  font-weight: bold;
  margin-left: 15px;
}

#loginSecurityTypoBox {
  margin-bottom: 2px;
  margin-left: 2px;
}

/* ------------------- */
/*LoginSecurity */

#loginSecurityBox {
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

#securityHeadTypo {
  font-weight: bold;
  font-size: 16px;
}

#currentEmailBox {
  margin-top: 15px;
}

#securityFieldHeadTypo {
  font-weight: bold;
  font-size: 14px;
}

#currentPassTypo {
  /* font-weight: bold; */
  font-size: 13px;
  margin-top: 10px;
  /* font-weight: 600; */
  color: #707070;
}

#forgotPassTypo {
  font-weight: bold;
  font-size: 10px;
  color: #071aff;
}

#footerTextContainer {
  display: flex;
  /* justify-content: center; */
}

#footerTypo {
  font-size: 14px;
  color: #707070;
}

#contactTypo {
  font-size: 14px;
  color: #071aff;
  margin-left: 4px;
  text-decoration: none;
}

#emailTypo {
  font-size: 14px;
}

#changePassBox {
  margin-top: 20px;
}

#forgotPassBox {
  cursor: pointer;
  width: 100px;
}

#newPassBox {
  margin-top: 10px;
  position: relative;
}

#ConfirmNewPassBox {
  margin-top: 10px;
  margin-bottom: 10px;
}

#btnBox {
  margin-top: 20px;
}

/*LoginSecurity google */

#securityGoogleBox {
  margin-left: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

#headTypoG {
  font-size: 16px;
  font-weight: bold;
}

#currentEmailBoxG {
  margin-top: 20px;
}

#typoHead {
  font-size: 14px;
  font-weight: bold;
  color: #707070;
}

#typoTitle {
  font-size: 14px;
  color: #707070;
}

#TitleGTypo {
  display: flex;
  align-items: center;
}

#footerContentBox {
  display: flex;
  justify-content: center;
}

#footerTypo {
  font-size: 16px;
  color: #707070;
}

#contactSupport {
  font-size: 16px;
  color: #071aff;
  margin-left: 4px;
}
