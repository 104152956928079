#maingrid {
  overflow-y: auto;
  height: -webkit-fill-available;
  padding: 30px 60px 0px;
  padding-bottom: 0px;
}
#maingrid::-webkit-scrollbar {
  width: 5px;
}
#maingrid::-webkit-scrollbar-track {
  background: #f1f1f1;
}
#maingrid::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 100px;
}

#dialogContentCon {
  margin: 16px 0px;
  display: flex;
  align-items: center;
}

#mainbox {
  margin-top: 30px;
}

.menu {
  background: #ffffff;
  border-radius: 5px;
  position: absolute;
  top: 60px;
  right: 30px;
  width: 100px;
  box-shadow: 0px 7px 20px #00000029;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
  z-index: 100;
}

.menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

#contenttypebtn {
  border: 1px solid #071aff;
  border-radius: 7px;
  color: #393939;
  text-transform: capitalize;
}
#contenttypeeditbtn {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-radius: 7px;
  color: #707070;
}
#contenttypebtnsgrid {
  display: flex;
  justify-content: space-between;
  margin: 20px 0px;
}
#campaigntypebtnsgrid {
  border: 2px solid #cccccc;
  border-radius: 4px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
}
#addbox {
  display: flex;
  justify-content: flex-end;
  margin: 16px 0px;
  align-items: center;
}
#addbtn {
  text-transform: capitalize;
  border-radius: 7px;
}
#calendarbtn {
  border: 1px solid #071aff;
  border-radius: 7px;
  color: #071aff;
}
#griditem2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #f0f0f0;
  border-radius: 6px;
  height: 100%;
}
#contentbox {
  text-align: center;
  border: 1px solid #b1aaaa;
  border-radius: 3px;
  margin-top: 10px;
  cursor: pointer;
  position: relative;
}
#threedot {
  position: absolute;
  top: 10px;
  left: 100%;
  padding: 0px;
}
#individualtogglebtn {
  border: 1px solid #cccccc;
  border-radius: 4px;
  color: #393939;
}
#individualselectbtn {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-radius: 4px;
  height: 30px;
  margin: 0px 15px;
}

#contentlistgrid {
  overflow-y: auto;
  height: 80%;
  margin-bottom: 10px;
  padding-right: 5px;
}
#contentlistgrid::-webkit-scrollbar {
  width: 5px;
}
#contentlistgrid::-webkit-scrollbar-track {
  background: #f1f1f1;
}
#contentlistgrid::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 100px;
}
#highlightofficon {
  fill: grey;
  margin-right: 90%;
  margin-top: 50%;
}
#updatebtn {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #071aff;
  border-radius: 23px;
  color: #071aff;
  float: right;
  margin-top: 10px;
}

@media (min-height: 600px) and (max-height: 767px) {
  #maingrid {
    padding: 20px 60px 0px;
  }
  #mainbox {
    margin-top: 0px;
  }
  #campaigntypebtnsgrid {
    margin: 5px 0px;
  }
}
