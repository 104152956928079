#individualAnchorMainBox {
  width: 100%;
  height: 55px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
  border-radius: 4px;
}

#anchorSubBox {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

#textFieldBox {
  width: 33%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #d0d0d0;
}
#textFieldBox3 {
  width: 33%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
