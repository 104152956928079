@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
* {
  font-family: "Poppins" !important;
}
.MuiTypography-root {
  color: #707070;
}
body {
  background-color: #f7f7f7;
}

.multiline-textfield-box textarea {
  font-size: 12px;
}

.tribe-dashbaord-first-con-grid {
  padding-top: 0px !important;
}

.box-display-style-for-next-and-back-button {
  display: flex;
  flex-direction: column !important;
  justify-content: space-between;
}

.box-display-style-for-next-and-back-button-with-justify {
  display: flex;
  flex-direction: column !important;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  /* margin-top: 12px; */
}

.box-display-style-starting-content-con {
  width: 100%;
  height: 96%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 12px;
}
.box-display-style-footer-content-con {
  padding: 0px 60px 0px;
  height: 15%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.box-display-style-for-next-and-back-button-with-justify-inside-con {
  display: flex;
  flex-direction: column !important;
  justify-content: space-evenly;
  width: 100%;
  height: 96%;
  margin-top: 12px;
  overflow-y: auto;
}

.box-display-style-for-inside-div-con {
  height: 85%;
}

.ttribe-back-next-button-con {
  padding: 0px 80px 0px;
  /* margin-bottom: 16px; */
  /* height: 5vh; */
  /* min-height: 12%; */

  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
}

.ttribe-back-next-button-inside-module-con {
  padding: 0px 28px 0px;
  margin-bottom: 32px;
}

.box-indiv-header-con {
  height: 105px !important;
  padding: 30px 60px 0px;
  background: white !important;
  border-radius: 10px;
}

.box-nested-header-con {
  padding: 0px 60px 0px;
}

.grid-nav-dash {
  align-items: center;
  margin-bottom: 12px;
  justify-content: space-evenly;
}

.ttribe-flow-chart-warning-msg-con {
  text-align: center;
  font-size: 2em;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
}

.ttribe-flowchart-iframe-img {
  height: 90%;
  width: 100%;
  object-fit: scale-down;
}

.tribe-pitch-deck-main-header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 12px 0;
}

.tribe-pitch-deck-disabled-tab-span {
  width: 100%;
  text-align: center;
  font-size: 15px;
  padding: 10px 0px;
  border-top: 1px solid #393939;
  border-left: 1px solid #393939;
  border-right: 1px solid #393939;
  background: white;
  color: #393939;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-right: 0.8em;
}

/* Start Pitch deck one page */

.tribe-pitch-one-page-main-con {
  width: 100% !important;
  height: 100%;
  display: contents !important;
  overflow-y: auto !important;
}

.tribe-pitch-deck-nav-button-group .MuiToggleButton-root {
  text-transform: none;
  font-family: "Poppins" !important;
  /* font-size: 0.7em !important; */
  border: 1.5px solid #393939;
  border-bottom: 0px solid transparent;
  color: #393939 !important;
  margin-right: 12px;
  border-left: 1.5px solid #393939 !important;
  border-radius: 4px !important;
  margin-left: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  background-color: white !important;
}

.tribe-pitch-deck-nav-button-group .MuiToggleButton-root:hover {
  border: 1.5px solid #071aff;
  border-left: 1.5px solid #071aff !important;
  border-bottom: 0px solid transparent;
  color: #071aff !important;
  font-weight: bold !important;
}

.tribe-pitch-deck-nav-button-group .Mui-disabled {
  border-right: 1.5px solid #393939 !important;
  border-top: 1.5px solid #393939 !important;
  margin: 0px !important;
  /* padding: 6px 16px 6px 12px !important; */
  width: 95% !important;
}

.tribe-pitch-deck-nav-button-group .Mui-selected {
  font-family: "Poppins" !important;
  border: 2px solid #071aff;
  border-left: 2px solid #071aff !important;
  color: white !important;
  border-radius: 4px !important;
  border-bottom: 0px solid transparent;
  background-color: #071aff !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.tribe-pitch-deck-nav-button-group .Mui-selected:hover {
  color: #ffffff !important;
}

.tribe-pitch-one-page-main-con {
  color: #393939;
}

.tribe-pitch-one-page-main-con .tribe-cover-con h1 {
  margin: 0px 0px;
  font-weight: bold;
  color: #071aff;
  font-size: 2.5em;
}

.tribe-pitch-one-page-main-con .tribe-cover-con p {
  margin: 10px 0px;
}

.tribe-cover-con {
  width: 100%;
  text-align: center;
}

.tribe-cover-grid-con {
  width: 100%;
}

.tribb-one-page-v-grid-con {
  margin: 0.5em 0px 0px !important;
}
.tribb-one-page-grid-con {
  margin: 0.5em 0px 1.5em !important;
}

.tribe-problem-con {
  margin: 1em 0px;
}
.tribe-problem-con p {
  white-space: pre-line;
  margin: 10px 0px;
}

.tribe-one-page-two-box-con {
  width: 100% !important;
  justify-content: space-evenly !important;
  margin: 1.5em 0px !important;
}

.tribe-pitch-small-box-con-left {
  border: 2px solid #868686;
  border-radius: 20px;
  margin: 0 !important;
  min-height: 15em;
  padding: 1em !important;
}

.tribe-pitch-small-box-con-right {
  border: 2px solid #868686;
  border-radius: 20px;
  margin: 0 !important;
  min-height: 15em;
  padding: 1em !important;
}

.tribe-pitch-single-box-con {
  border: 2px solid #868686;
  border-radius: 20px;
  margin: 0 !important;
  padding: 1em !important;
  min-height: 15em;
}
.tribe-pitch-footer-box-con {
  /* border: 2px solid #868686; */
  /* border-radius: 45px 10px; */
  margin: 0 !important;
  /* min-height: 14em; */
  margin-bottom: 1em !important;
}

.sub-heading {
  color: #071aff;
  margin: 0px;
}

/* customer start */

.tribe-pitch-customer-module-con {
  width: 100%;
  height: 100%;
}

.ttribe-customer-seg-con {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 82%;
  align-items: center;
  padding-top: 1em;
}

.ttribe-customer-seg-img-con {
  width: 30%;
  text-align: center;
}

.ttribe-customer-seg-img-con img {
  width: 80%;
}

.ttribe-customer-seg-name-con {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 70%;
  margin: 0.1em 1.7em 0.2em;
}

.ttribe-customer-seg-name {
  margin-bottom: 10px !important;
}

.ttribe-customer-seg-name-con .MuiInputBase-root {
  border-radius: 18px !important;
}

.ttribe-customer-seg-name-con .MuiInputBase-root .Mui-disabled {
  -webkit-text-fill-color: #393939 !important;
  /* text-shadow: 0px 4px 4px rgb(2 2 2 / 25%); */
}
/* customer end */

/* market start */

.tribe-market-module-con {
  height: 100%;
  width: 100%;
}

.pitch-market-cirle-con {
  display: flex;
  height: 94%;
  justify-content: center;
  width: 80%;
  text-align: center;
  margin: auto;
}

.pitch-market-outer-cir-con {
  background-color: #224afb;
  width: 90%;
  height: 90%;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}
.pitch-market-outer-cir-con span {
  color: white;
  position: relative;
  top: 1%;
  text-align: center;
}

.pitch-market-first-inner-cir-con span,
.pitch-market-sec-inner-cir-con span {
  color: rgb(0, 0, 0);
  position: relative;
  top: 3%;
  text-align: center;
}

.pitch-market-first-inner-cir-con {
  background-color: #98abff;
  width: 80%;
  height: 90%;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.5em;
}
.pitch-market-sec-inner-cir-con {
  background-color: #dbe2ff;
  width: 70%;
  height: 90%;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.5em;
}
/* market end */

/* problem solution and difffer start */

.vl {
  border-left: 5px solid #e7dede;
  height: 12em;
  border-radius: 1em;
}

.tribe-pitch-prob-module-con {
  width: 100%;
  height: 100%;
}

.ttribe-solution-name {
  background: #ffffff;
  /* border: 1px solid #393939; */
  border-radius: 18px;
  white-space: pre-line;
}
/* .ttribe-solution-name .MuiInputBase-root textarea {
  height: 10em !important;
} */

/* problem solution and difffer end */

.tribe-value-module-content-con {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 1em;
}

.tribe-value-module-content-left {
  width: 25%;
}

.tribe-value-module-content-right {
  width: 100%;
}

.tribe-value-module-content-right .MuiInputBase-root textarea {
  height: 7em !important;
  overflow: visible !important;
}
.tribe-value-module-content-right .MuiInputBase-root {
  border-radius: 25px;
}

/* value proposition module start */

.tribe-pitch-value-prop-modul-con {
  width: 100%;
  height: 100%;
}

.tribe-pitch-value-prop-modul-con .values-con {
  margin: 0.5em 2em;
}
/* .tribe-value-module-content-right .MuiTextField-root {
  width: 95%;
} */

.tribe-value-module-content-right .tribe-value-right-content-con {
  border: 1px solid #bdbdbd;
  border-radius: 18px;
  padding: 1em;
  background-color: #ffffff;
  margin-right: 0.1em;
}

.tribe-value-module-content-right .tribe-value-right-content {
  white-space: pre-line;
}

.tribe-value-module-content-right .tribe-value-right-content:disabled {
  background-color: #ffffff !important;
  color: #393939 !important;
  font-size: 1.1em !important;
}
/* value proposition module end */

/* Business module start */

/* Business module end */
/* The Ask module start */

.tribe-ask-fin-module-con {
  width: 100%;
  height: 100%;
}

.tribe-ask-fin-module-con .tribe-ask-con {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* height: 94%; */
  text-align: center;
}

.tribe-ask-fin-module-con .tribe-ask-fun-btn {
  background: #071aff !important;
  color: white !important;
  padding: 1em;
  border-radius: 0.9em;
  font-size: 18px;
  width: 45%;
  margin: auto;
}

.tribe-ask-fin-module-con .tribe-ask-fun-btn:hover {
  background: #0412b2 !important;
  /* color: white !important; */
}

.tribe-ask-achieve-main-con {
  display: flex;
  justify-content: space-around;
  width: 100%;
  text-align: center;
}

.tribe-ask-achieve-con {
  width: 50%;
}

.tribe-ask-achieve-btn {
  border: 2px solid #071aff;
  border-radius: 12px;
  padding: 0.5em;
  color: #071aff;
  font-size: 1.1em;
  /* height: 35%; */
}

.tribe-ask-con h4 {
  margin: 10px 0;
}
.tribe-ask-con p {
  margin: 10px 0;
}
/* The Ask module end */
/* The Financial module start */

.tribe-fin-module-sub-con {
  padding: 0.5em 0px;
  text-align: center;
  width: 100%;
  height: 70%;
}

.tribe-fin-module-text-con {
  display: flex;
  align-items: center;
  width: 100%;
}
.tribe-fin-module-text-con h4 {
  width: 30%;
}
.tribe-fin-module-text-con p {
  white-space: pre-line;
  width: 60%;
  text-align: left;
  background: #ffffff;
  border: 1px solid #e7dede;
  border-radius: 10px;
  padding: 0.7em;
  margin: 0.5em 0;
}

.tribe-pl-table-scrollable-container {
  -webkit-user-select: none; /* webkit (Chrome, Safari) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -ms-user-select: none; /* IE10+ */
  user-select: none; /* standard syntax */
}

.tribe-table-fin-header-col {
  text-align: center;
  font-weight: bold;
  color: #393939;
  width: 20%;
}
.tribe-pl-table-fin-header-col {
  text-align: center;
  font-weight: bold;
  color: #393939;
  width: 73px;
  margin: auto;
}

.tribe-pl-table-fin-qtr-con {
  width: 76px;
  text-align: center;
  color: blue;
  font-weight: bold;
  margin: auto;
}
.tribe-pl-table-fin-header-row {
  text-align: left;
  font-size: 12px;
  color: #393939;
  width: 150px;
  height: 20px;
  margin: auto;
  display: flex;
  align-items: center;
}
.tribe-pl-out-table-fin-header-col {
  text-align: center;
  font-weight: bold;
  color: #393939;
  width: 76px;
}

/* The Financial module end */
/*contact info module start */
.tribe-contact-info-sub-con {
  width: 100%;
  display: flex;
  text-align: center;
  margin-top: 1em;
  align-items: center;
}

.tribe-contact-module-text-con {
  display: flex;
  align-items: center;
  width: 100%;
}

.contact-group-1 {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  border: 1px solid #e7dede;
  margin-right: 12px;
  border-radius: 10px;
  padding: 0 1em;
  min-height: 3.5em;
  max-height: 3.5em !important;
  font-size: 13px !important;
  overflow-y: auto;
}
.contact-group-1 img {
  margin-right: 1em;
}
.contact-group-1 h4 {
  margin-right: 1em;
}
/*contack info module end */

.tribb-one-page-grid-con
  .tribe-one-page-two-box-con
  .tribe-pitch-small-box-con-left:hover {
  border: 2px solid #071aff;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  transition: 0.3s ease-out;
}

.tribe-contact-module-sub-con {
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  justify-content: flex-start;
}

.tribb-one-page-grid-con
  .tribe-one-page-two-box-con
  .tribe-pitch-small-box-con-right:hover {
  border: 2px solid #071aff;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  transition: 0.3s linear;
}

.tribe-pitch-single-box-con:hover {
  border: 2px solid #071aff;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
  transition: 0.3s linear;
}

/* .tribe-pitch-footer-box-con:hover {
  border: 2px solid #071aff;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 60px 40px -7px;
  transition: 0.3s linear;
} */

.tribe-pitch-one-page-no-info-con {
  font-size: 1.1em;
  font-weight: bold;
  text-align: center;
  width: 100%;
  margin-bottom: 1em;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tribe-pitch-one-page-no-info-con .MuiButtonBase-root .MuiSvgIcon-root {
  font-size: 1.2em !important;
}

.tribe-one-page-present-main-con {
  padding: 1% 8%;
  background: white;
}

.cus-cash-flow-row {
  font-size: 12px !important;
  /* height: 66%; */
  height: 3em !important;
  width: 95%;
}

.cus-cash-flow-row-disabled {
  font-size: 12px !important;
  font-weight: 600 !important;
  /* height: 66%; */
  height: 3em !important;
  width: 95%;
  background: #e3efff !important;
  color: #000000 !important;
}

.cus-cash-flow-row-disabled .Mui-disabled {
  -webkit-text-fill-color: #000000c2 !important;
}

/* End Pitch deck one page */

/* start insights page */

.tribe-ploutlook-table-con {
  width: 70vw;
  /* height: 70vh; */
  overflow-x: auto;
  overflow-y: hidden;
  margin: auto;
}

.tribe-financial-insight-dashboard-charts-con {
  width: 100%;
  /* height: 55vh; */
  /* height: 10%; */
  margin: auto !important;
  /* overflow-y: scroll; */
}
.tribe-financial-insight-dashboard-charts-con h2 {
  color: #5f5e5e;
  margin: 10px 0;
  font-size: 20px;
}
.tribe-financial-insight-dashboard-charts-sub-con {
  width: 100% !important;
  display: flex !important;
  text-align: center;
  margin: auto;
}

.tribe-inside-chart-div-con {
  max-height: 55vh;
  min-height: 55vh;
  overflow: auto;
}

.tribe-vertical-carousel {
  height: 50vh;
  overflow: hidden;
  position: relative;
}

.tribe-vertical-carousel div {
  transition: transform 0.5s ease-out;
}

.tribe-slides {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: absolute;
  width: 100%;
  transition: transform 0.5s;
}

.tribe-slide {
  height: 100%;
  width: 100%;
}

.tribe-chart-page-con {
  height: 35vh;
}

.tribe-financial-insight-dashboard-charts-div {
  width: 50%;
  text-align: center;
  /* padding: 1% */
  margin: 0 10px;
}

.tribe-financial-insight-dashboard-charts-div:hover {
  transition: 0.3s ease-out;
}

.tribe-financial-insight-dashboard-charts-full-div {
  width: 100%;
  text-align: center;
  margin: auto;
}

.tribe-fin-chart-year-sel-main-con {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}

.tribe-fin-chart-year-sel-con {
  text-align: center !important;
  width: 30% !important;
}

.tribe-fin-chart-year-sel-title-con {
  width: 80%;
  text-align: center;
}
.tribe-fin-chart-year-sel-title-con p {
  margin: auto;
  padding-left: 26%;
  text-shadow: 1px 1px 0 #737374;
  font-size: 14px;
  color: #5f5e5e;
  letter-spacing: 1.2px;
}

.fin-chart-more-detail-con {
  text-align: left;
}
.fin-chart-more-detail-con p {
  color: #5f5e5e;
}
.fin-chart-more-detail-con p span {
  cursor: pointer;
  /* font-size: 20px; */
  font-weight: 600;
  color: #071aff;
  font-style: italic;
}

.fin-chart-more-detail-con p span:hover {
  color: #0412b2;
}

.tribe-fin-chart-head-bottom-line {
  margin-bottom: 12px;
}

.tribe-fin-chart-year-sel {
  height: 2em;
  font-size: 12px;
}

.tribe-apex-chart-con .apexcharts-canvas {
  margin: auto !important;
}

.dots {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.fin-dot {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: gray;
  margin: 10px;
  cursor: pointer;
}

.fin-active {
  background-color: black;
}

.tribe-slide-vertical-car-nav-btn {
  text-shadow: 1px 1px 0 #737374 !important;
  font-size: 2em !important;
  fill: #5f5e5e !important;
  letter-spacing: 1.2px;
  cursor: pointer;
}

.tribe-slide-vertical-car-nav-con {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
/* end insights page */

.tribe-venture-row-list-con {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

/* welcome page enhancement start */

.tribe-indiv-box-container {
  height: 31vh;
  overflow-y: auto;
  background-color: #fcfcfd;
  border: 1px solid #e1e2e9;
  border-radius: 8px;
  margin: 10px 0;
}

.tribe-indiv-row-con {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  /* column-gap: 2em;
  margin: 2em 0; */
}

.tribe-indiv-box-con {
  width: 31%;
  margin-left: 1em;
  margin-top: 1em;
}

.tribe-invite-main-con {
  width: 60%;
}

.tribe-invite-opts-con {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  column-gap: 3em;
}

.tribe-invite-opts-con p {
  color: #071aff;
  font-size: 14px;
  border-bottom: 1px solid;
  cursor: pointer;
}

.tribe-invite-card-name-con {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
}

.tribe-invite-hide-icon-con {
  width: 2em;
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tribe-invite-hide-icon {
  width: 2em;
}

.tribe-hidden-shared-venture-list {
  display: flex;
}

.tribe-no-hidden-venture-con {
  font-size: 1.2em;
  margin: auto;
  padding: 12px;
}

/* welcome page enhancement end*/

/* view business plan summary start*/

.tribe-dashboard-view-biz-plan-summary {
  background-color: #071aff !important;
  color: white !important;
  margin: 0px 10px !important;
  text-transform: unset !important;
  font-weight: 600 !important;
}

.tribe-biz-plan-title-con {
  font-size: 1.8em;
  font-weight: bold;
  margin: auto;
  text-align: center;
  color: #071aff;
  padding: 1em 0;
  text-transform: capitalize;
}
.tribe-fin-plan-title-con{
  /* font-size: 1.8em; */
  font-weight: bold;
  margin: auto;
  text-align: center;
  color: #071aff;
  padding: 0.2em 0;
  text-transform: capitalize;
}

.tribe-biz-dialog-title-con {
  background: white;
  min-height: 9vh;
  display: flex;
  align-items: center;
}

.tribe-fin-close-dialog-title-con {
  background: white;
  min-height: 2vh;
  display: flex;
  align-items: center;
}

.tribe-biz-summary-tabs-con {
  position: sticky;
  top: 0px;
  background-color: rgb(255, 255, 255);
  z-index: 1;
}

.tribe-biz-summary-tabs-con .Mui-selected {
  color: #071aff;
  background: #c4c0c042;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.tribe-biz-tab-section-main-con {
  padding: 0 2em;
  height: 68vh;
  overflow-y: auto;
}

.tribe-summary-venture-profile-main-con {
  margin-top: 1em;
}

.tribe-summary-venture-profile-con {
  height: 50vh;
}

.tribe-summary-venture-img-con {
  display: flex;
  flex-direction: column !important;
  align-items: center;
  justify-content: space-evenly;
  /* height: 55vh; */
}

.tribe-summary-fund-con {
  height: 12vh;
  width: 16vw;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #a7a0a0;
  box-shadow: 4px 4px 22px 1px #cccccc;
  border-radius: 10px;
  color: #707070;
}

.tribe-summary-view-chart {
  height: 6vh;
  width: 18vw;
  text-align: center;
  border: 1px solid #a7a0a0;
  box-shadow: 4px 4px 22px 1px #cccccc;
  border-radius: 10px;
  color: #707070;
  text-transform: none !important;
  color: white !important;
  background: #071aff !important;
}

.tribe-summary-view-chart:hover {
  color: #071aff !important;
  background: white !important;
}

.tribe-summary-fund-con span {
  color: #514f4f;
  font-weight: bold;
}

.tribe-summary-img {
  width: 35vh !important;
}

.tribe-summary-milestone-img {
  width: 19em !important;
}

.tribe-summary-description-con {
  padding: 0 1em;
  display: flex;
  flex-direction: column;
  height: 54vh;
  justify-content: center;
}

.tribe-summary-milestones-table-con {
  margin-top: 10px !important;
  padding: 0 1em;
  display: flex;
  flex-direction: column;
  height: 65vh;
  /* justify-content: center; */
}

.tribe-summary-description-con .title {
  font-size: 2vh;
  color: #707070;
  font-weight: 600;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.tribe-summary-vision-stmt-des-con .title {
  margin-top: 10px;
}

.tribe-summary-description-con .description {
  background: #ffffff;
  border: 1px solid #a7a0a0;
  border-radius: 10px;
  padding: 1em;
  height: 17vh;
  overflow-y: auto;
  font-size: 1.6vh;
  line-height: 1.8;
  display: flex;
  align-items: center;
}

.tribe-milestons-table {
  height: 60vh !important;
  border: 1px solid #c8c5c5 !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  overflow-y: auto !important;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.tribe-milestons-table .rdt_TableCol:hover {
  color: #071aff;
  font-weight: bold;
  border-bottom: 1px solid #071aff;
}

.contact-group-1-bizplan-summary {
  font-size: 13px !important;
}

.tribe-pitch-footer-box-con-summary {
  margin: 1em !important;
}

.tribe-fin-chart-tab-data-main-con {
  padding: 1em;
  border-radius: 12px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1em 3em;
}

.tribe-fin-chart-tab-data-con {
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  color: #707070;
  width: 100%;
}

.tribe-fin-chart-tab-data-main-con .fin-data {
  font-weight: bold;
}

.tribe-year-drop-main-con {
  display: flex;
  align-items: center;
  column-gap: 1em;
  margin-bottom: 10px;
  /* justify-content: flex-end; */
  width: 15%;
}

.tribe-fin-char-sec-main-con {
  margin: 2em 0;
  padding: 1em;
  max-height: 45vh;
  min-height: 45vh;
  border-radius: 12px;
  box-shadow: rgb(17 17 26 / 10%) 0px 4px 16px, rgb(17 17 26 / 5%) 0px 8px 32px;
}
.tribe-inside-chart-div-con-for-biz-plan {

  max-height: 45vh;
  min-height: 45vh;
  overflow: auto;
}

.tribe-mils-expand-table-com .rdt_TableCell {

  justify-content: flex-end !important;
  background: #a0a2aa38 !important;

}
/* view business plan summary end*/

.tribe-journy-map-ifram-con {
  width: 100%;
  height: 95%;
}

.tribe-journy-map-ifram-con .geToolbarContainer {
  height: 3em !important;
}

.pitch-thank-you-content-con {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  /* width: 80%; */
  margin-bottom: 2vh;
  margin-left: 16vh;
}

.pitch-thank-you-content-con-row1 {
  width: 10%;
}
.pitch-thank-you-content-con-row2 {
  width: 40%;
}

.tribe-fin-close-dialog-title-con svg {

 font-size: 19px !important;

}

.ttribe-market-ff-text-title-con textarea {
  height: 9.5vh !important;
}


@media only screen and (min-width: 1250px) and (max-width: 1400px) {
  .ttribe-back-and-next-btn-text {
    font-size: 0.8rem !important;
  }

  .multiline-textfield-box textarea {
    font-size: 14px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .multiline-textfield-box textarea {
    font-size: 11 px;
  }
}

@media only screen and (min-width: 1401px) and (max-width: 1450px) {
  .multiline-textfield-box textarea {
    font-size: 11 px;
  }
}

@media only screen and (min-width: 200px) and (max-width: 600px) {
  .ttribe-back-and-next-btn-text {
    font-size: 0.8rem;
  }

  .multiline-textfield-box textarea {
    font-size: 0.8rem;
  }
}

::-webkit-scrollbar {
  width: 0.5em !important;
  height: 0.5em !important;
}

::-webkit-scrollbar-track {
  background: rgb(219, 211, 211);
  /* background: #f1f1f1; */
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5em;
}
