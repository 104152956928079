#contentBox {
  height: 60vh;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
  /* border: 1px solid red; */
  column-gap: 4%;
}

#addIconBox {
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  bottom: 25px;
  right: 0px;
  background-color: #071aff;
  cursor: pointer;
}

#addIcon {
  font-size: 32px;
  color: #ffffff;
}
