#mainBox {
  width: 100%;
  height: 60vh;
}

#mainYearBox {
  display: flex;
  justify-content: space-evenly;
  margin-top: 3vh;
  height: 30%;
  width: 100%;
}

#yearBox {
  display: flex;
  flex-direction: column;
  width: 100%;
}

#yearTypoBox {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 0.5vh;
  margin-bottom: 0.5vh;
  position: relative;
}

#yearTypo {
  color: #393939;
  font-weight: bold;
  font-size: 1.6vh;
}

#textFieldBox {
  width: 100%;
}

#allocationBox {
  margin-top: 3vh;
}

#allocationTypoBox {
  margin-bottom: 2vh;
}

#individualAllocationMainBox {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

#individualAllocation {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 19%;
  align-items: center;
}

#allocationImg {
  width: 78px;
  height: 78px;
}

#individualAllocationTypo {
  color: #393939;
  margin-top: 1vh;
  margin-bottom: 1vh;
  font-weight: bold;
}

#allocationTextFieldBox {
  width: 154px;
  height: 50px;
}

#allocationHeadTypo {
  font-size: 21px;
  color: #393939;
}
