#box2 {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  /* margin-left: 7vh; */
}

#typography2 {
  font-size: 12px;
  margin-bottom: 20px;
  /* margin-left: 7vh; */
}

#typography3 {
  font-size: 14px;
  margin-bottom: 20px;
  margin-top: 20px;
  /* margin-left: 7vh; */
}

/* PlTable css */
Chrome,
Safari,
Edge,
Opera input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="search"] {
  -moz-appearance: textfield;
}

/* remove outsideof arrows button */
input[type="search"]::-webkit-outside-spin-button {
  display: none;
}

.monthRow {
  width: 76;
  text-align: center;
  color: #393939;
}

#downloadicon {
  background-color: white;
  box-shadow: 0px 1px 6px #071aff26;
  border-radius: 18px;
  color: #707070;
  margin-left: 5px;
}

#maingrid::-webkit-scrollbar {
  width: 5px;
}
#maingrid::-webkit-scrollbar-track {
  background: #f1f1f1;
}
#maingrid::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 100px;
}

#currencyDropdownBox {
  display: flex;
  align-items: center;
  margin-top: 12px;
}

#currencyTypoBox {
  display: flex;
  align-items: center;
}

#currencyTypo {
  margin-right: 15px;
  font-size: 14px;
}

/* upload excel css */
#uploadbox {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 60%;
  justify-content: space-evenly;
  padding: 50px;
  border: 1px dashed #707070;
  border-radius: 22px;
  cursor: pointer;
  width: 380px;
}
#uploadgrid {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  height: 80%;
}
#imgicon {
  transform: matrix(0, -1, 1, 0, 0, 0);
  width: 50px;
  margin-left: 40%;
}
