#mainGrid {
  align-items: center;
  border-bottom: 1px solid #e1e2e9;
  margin-top: 5px;
  padding-bottom: 5px;
}

#individualGriditem {
  display: flex;
  justify-content: center;
}

#subBox {
  margin-top: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

#imageBox {
  /* width: 60px;
  height: 60px; */
  border: 1px solid #707070;
  border-radius: 50px;
  width: 45px;
  height: 45px;
}

/* #defaultprofileIcon {
  width: 60px;
  height: 60px;
  border: 1px solid #707070;
  border-radius: 50px;
  padding: 10px;
} */

#nameTypo {
  font-size: 14px;
  color: #5f5e5e;
  font-weight: bold;
}

#emailTypo {
  font-size: 14px;
  color: #5f5e5e;
}

#menuItemTypo {
  font-size: 14px;
}

#individualBox {
  margin-left: 4%;
}

#resendTypo {
  font-size: 14px;
  color: #071aff;
  text-decoration: underline;
  cursor: pointer;
}
