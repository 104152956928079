#barBox {
  width: 75%;
}

#circleBox {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

#circleBoxTypo {
  font-size: 12px;
}
