/* definition css */
#typography1 {
  padding: 15px 30px;
  font-size: 20px;
  font-weight: bold;
}

#definitionbox {
  border: solid 1px #707070;
  border-radius: 13px;
  height: 80%;
}

#definitionitem {
  display: flex;
  justify-content: center;
  align-items: center;
}

#typography2 {
  border-bottom: solid 1px #707070;
  border-radius: 13px;
  padding: 15px 20px;
  font-size: 15px;
}
#iconbtn {
  padding: 0px;
  margin-left: 5px;
}

/* defensibility css */
#firstrow {
  border: 1px solid #071aff;
  border-radius: 5px;
  padding: 10px 20px;
  padding-right: 0px;
}
#otherrow {
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 10px 20px;
  padding-right: 0px;
}
#defensibilityname {
  font-size: 14px;
  font-weight: bold;
}

/* #maingrid {
  overflow-y: scroll;
  height: -webkit-fill-available;
  padding: 10px 60px;
}
#maingrid::-webkit-scrollbar {
  width: 5px;
}
#maingrid::-webkit-scrollbar-track {
  background: #f1f1f1;
}
#maingrid::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 100px;
} */

/* Solutions Grid1 css */

#persona {
  cursor: pointer;
  text-align: center;
  flex: 0 0 150px;
  position: sticky;
  left: 0px;
  background: #f8f9ff 0% 0% no-repeat padding-box;
  z-index: 5;
}

#persona:hover {
  background-color: #c7c6c6;
  box-shadow: 0 4px 12px rgb(0 0 0 / 15%);
}

#personagrid {
  display: flex;
  justify-content: center;
  align-items: center;
}

#gridbox {
  flex-direction: column;
  background: #f8f9ff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 11px;
  padding: 20px 0px;
  margin-top: 2em;
  overflow-x: scroll;
}

#gridbox::-webkit-scrollbar {
  height: 5px;
}
#gridbox::-webkit-scrollbar-track {
  background: #f1f1f1;
}
#gridbox::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 100px;
}

#addcolumnbtn {
  float: right;
  position: sticky;
  top: 0;
  z-index: 5;
  padding: 0px;
}

#columntypography {
  position: sticky;
  left: 0px;
  background: #f8f9ff 0% 0% no-repeat padding-box;
  z-index: 5;
  color: #393939;
  flex: 0 0 150px;
  text-align: center;
}

#upbtn {
  width: 25px;
  height: 25px;
  position: sticky;
  float: right;
  bottom: 7%;
  right: 0%;
  cursor: pointer;
  margin-left: 15px;
}

#maingrid {
  overflow-y: auto;
  height: 100%;
  padding: 0px 60px 0px;
}
#maingrid::-webkit-scrollbar {
  width: 5px;
}
#maingrid::-webkit-scrollbar-track {
  background: #f1f1f1;
}
#maingrid::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 100px;
}

#othercolumntypographys {
  color: #393939;
  flex: 0 0 25%;
  text-align: center;
}

#problemMapCauseAndProbGrid {

  flex: 0 0 25% !important;
  text-align: center;
  
}