#topTypoBox {
  display: flex;
  align-items: center;
}

#topTypo {
  color: #5f5e5e;
  font-weight: bold;
}

#topSubTypo {
  color: #868686;
  font-size: 12px;
}

#bottomContentBox {
  width: 100%;
  margin-top: 25px;
  display: flex;
}

#revenueStreamMainBox {
  width: 50%;
}

#revenueStreamTypo {
  font-size: 12px;
  color: #5f5e5e;
  font-weight: bold;
}

#addIcon {
  font-size: 22px;
  color: #ffffff;
}

#imgTypoMainBox {
  width: 50%;
}

#imageBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

#img {
  width: 180px;
  height: 110px;
  /* border: 1px solid black; */
}

#exampleTypoMainBox {
  margin-top: 12px;
}

#exampleTypo {
  font-size: 12px;
  color: #5f5e5e;
  font-weight: bold;
}

#exampleContentTypo {
  color: #868686;
  font-size: 12px;
}

#backNextBox {
  border: 1px solid #ffffff;
  margin-top: 9px;
}

#addIconButtonBox {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}