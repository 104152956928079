#headerTypoBox {
  margin-top: 20px;
  margin-left: 67px;
  margin-bottom: 20px;
}

#headTypo {
  font-weight: bold;
  color: #5f5e5e;
  /* text-decoration: underline; */
}

#myProjectBox {
  margin-right: 67px;
  margin-bottom: 10px;
}

#myProjectSubBox {
  margin-left: 67px;
  margin-top: 5px;
  margin-bottom: 5px;
}

#myProjectTypo {
  color: #5f5e5e;
  font-size: 14px;
  font-weight: bold;
}

#myProjectContentBox {
  /* box-shadow: 0px 3px 6px #00000029; */
  /* height: 11vw; */
  height: 25vh;
  background-color: #fcfcfd;
  margin-top: 5px;
  border-radius: 8px;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  /* background-color: #FCFCFD ; */
  border: 1px solid #e1e2e9;
  width: 100%;
}

#arrowBox {
  display: flex;
  align-items: center;
  height: 100%;
  width: 10%;
  /* margin-right: 10px; */
  /* border: 1px solid red; */
  justify-content: center;
}

#arrowIcon {
  font-size: 50px;
  color: #b1aaaa;
}

#midContentBox {
  width: 100%;
  /* width: 70vw; */
  height: 21vh;
  display: flex;

  align-items: center;
  /* justify-content: space-evenly; */
  gap: 1.5%;
}

#myProjectWhiteBox {
  background-color: white;
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-left: 10px;
}

#whiteBoxTypo {
  color: #5f5e5e;
  font-size: 13px;
  font-weight: bold;
  width: 80%;
}

#newVentureBox {
  background-color: white;
  border-radius: 5px;
  width: 33%;
  border: 1px solid #f7f7f7;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: 0px 3px 6px #071aff15;
}

#newVentureSubBox {
  height: 68%;
  border-bottom: 1px solid #e1e2e9;
  display: flex;
  justify-content: center;
}

#sampleProjectBox {
  background-color: white;
  border-radius: 5px;
  width: 26%;
  /* border: 1px solid #f7f7f7; */
  display: flex;
  flex-direction: column;
  height: 95%;
  /* box-shadow: 0px 3px 6px #071aff15; */
  justify-content: center;
  align-items: center;
  text-align: center;
}

#sampleProjectTypo {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #071aff;
}

#invtedContentTypo {
  margin-left: 10px;
  margin-bottom: 10px;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#viewLessBox {
  display: flex;
  justify-content: center;
  margin-top: 4px;
  cursor: pointer;
  margin: 10px;
}

#viewLessTypo {
  font-size: 14px;
  color: #071aff;
  font-weight: bold;
  display: flex;
  align-items: center;
}

#viewMoreBox {
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin: 10px;
}

#viewMoreTyo {
  font-size: 14px;
  color: #071aff;
  font-weight: bold;
  display: flex;
  align-items: center;
}

#addIconBox {
  display: flex;
  justify-content: center;
}

#projectInvitedBox {
  /* margin-right: 67px; */
  margin-bottom: 20px;
  /* margin-bottom: 10px; */
  /* border: 1px solid red; */
  height: 41vh;
}

#projectInvitedSubBox {
  margin-left: 67px;
  margin-top: 5px;
  margin-right: 67px;
  height: 100%;
}

#projectInvitedTypoBox {
  display: flex;
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
  margin-top: 2px;
  height: 3vh;
  width: 100%
}

#projectInvitedTypo {
  font-weight: bold;
  color: #5f5e5e;
  font-size: 14px;
}

#lineBox {
  display: flex;
  align-items: center;
  margin: 0px 10px;
}

#line {
  border: 1px solid #ff7f00;
  width: 40px;
}

#comingSoonTypo {
  font-size: 18px;
  color: #ff7f00;
  font-weight: bold;
}

#listViewBox {
  margin-right: 20px;
  display: flex;
  align-items: center;
}

#listViewTypo {
  font-size: 12px;
  font-weight: bold;
  color: #5f5e5e;
  text-decoration: underline;
}

#invitedContentBox {
  box-shadow: 0px 3px 6px #00000029;
  height: 14vw;
  margin-top: 5px;
  background-color: #f7f7f7;
  border-radius: 8px;
  /* display: flex; */
  /* justify-content: space-between; */
  /* align-items: center; */
  /* flex-direction: column; */
  /* border: 1px solid red; */
}

#noInvitedContentBox {
  /* box-shadow: 0px 3px 6px #00000029; */
  height: 13vw;
  margin-top: 5px;
  background-color: #fcfcfd;
  border-radius: 8px;
  display: flex;
  /* justify-content: space-between; */
  /* align-items: center; */
  /* flex-direction: column; */
  /* border: 1px solid red; */
  align-items: center;
  justify-content: center;
  border: 1px solid #e1e2e9;
}

#midContentBoxInvited {
  width: 100%;
  height: 85%;
  display: flex;
}

#midContentBoxInvitedExist {
  width: 100%;
  height: 95%;
  display: flex;
  /* justify-content: space-evenly; */
  /* margin-top: 5px; */
  flex-wrap: wrap;
  gap: 1.5em;
  /* border: 1px solid red; */
}

#whiteBoxInvited {
  background-color: white;
  /* width: 22%; */
  width: 48%;
  display: flex;
  /* flex-direction: column; */
  /* justify-content: space-between; */
  border-radius: 5px;
  /* margin-left: 2px; */
  box-shadow: 0px 3px 6px #071aff17;
}

#imgBox {
  width: 30px;
  height: 30px;
  margin-top: 10px;
  margin-left: 10px;
  background-color: #f7f7f7;
}

#whiteBoxTypoInvite {
  font-size: 14px;
  font-weight: bold;
  color: #5f5e5e;
}

/* existing user */

#existingTypoBox {
  /* margin: 3em 4em 0px;
   */
  /* display: flex; */
  /* align-items: center; */
  margin-top: 16px;
  margin-left: 67px;
  margin-bottom: 8px;
}

#headSubTypo {
  font-weight: bold;
  color: #5f5e5e;
  font-size: 12px;
  margin-left: 2%;
}

#existingProjectSubBox {
  margin-left: 67px;
  margin-top: 5px;
}

#projectBox {
  background-color: white;
  width: 33%;
  border-radius: 5px;
  /* margin-left: 10px; */
  box-shadow: 0px 3px 6px #071aff15;
  display: flex;
  flex-direction: column;

  cursor: pointer;
  height: 100%;
  justify-content: space-between;
}

#projectImgBox {
  display: flex;
  justify-content: center;
  border: 1px solid gray;
  width: 48px;
  height: 48px;
  align-items: center;
  border-radius: 50%;
}

#projectImgBoxVisible {
  display: flex;
  justify-content: center;
  border: 1px solid #e1e2e9;
  width: 48px;
  height: 48px;
  align-items: center;
  border-radius: 50%;
  margin-top: 4%;
  box-shadow: 4px 3px 6px #071aff17;
}

#projectTypoBox {
  text-align: center;
}

#projectTypo {
  font-size: 12px;
  font-weight: bold;
  color: #5f5e5e;
}

/* ----------------- */

#projectSubBox {
  display: flex;
  justify-content: center;
  margin-top: 5%;
  width: 100%;
  height: 14vh;
  border-bottom: 1px solid #e1e2e9;
  align-items: center;
  margin: 1vh 0;
}

#ventureNameBox {
  display: flex;
  justify-content: space-between;
  /* margin-right: 10px;
  margin-bottom: 5%; */
  margin: auto;
  height: 5vh;
}

#ventureNameTypo {
  font-size: 14px;
  color: #5f5e5e;
  font-weight: bold;
  line-height: 1.4;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#MoreVert {
  font-size: 17px;
  color: #5f5e5e;
}

#editIcon {
  font-size: 17px;
  margin-right: 7px;
  margin-left: 7px;
}

/* ----------------------- */

@media (max-width: 1382px) {
  #sampleProjectTypo {
    font-size: 13px;
  }
}

/* @media (min-width: 600px) and (max-width: 1382px) {
  #sampleProjectTypo {
    font-size: 13px;
  }
} */

@media (max-width: 1318px) {
  /* @media (min-width: 600px) and (max-width: 1382px) { */
  #sampleProjectTypo {
    font-size: 11px;
  }
}

@media (max-width: 1254px) {
  /* @media (min-width: 600px) and (max-width: 1382px) { */
  #sampleProjectTypo {
    font-size: 10px;
  }
}
