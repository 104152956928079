#mainBox {
  display: flex;
  column-gap: 3;
}

#leftFlex {
  width: 18%;
  display: flex;
  flex-direction: column;
  transition: width 700ms, height 700ms;
  transition-timing-function: ease;
  margin-top: 2.6%;
}

#rightFlex {
  /* width: 82%; */
  display: flex;
  flex-direction: column;
  transition: width 700ms, height 700ms;
  transition-timing-function: ease;
}

#leftHeadBox {
  width: 30%;
  padding: 0px 2em;
}

#mainTypo {
  font-size: 25px;
  color: #393939;
  font-weight: bold;
  margin-left: 10px;
}

#navBox {
  height: 74vh;
  /* border: 3px solid #071aff; */
  width: 100%;
  border-radius: 5px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 1;
  padding-top: 10px;
  padding-bottom: 10px;
  overflow-y: scroll;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
}

/* #navBox::-webkit-scrollbar {
  display: none;
} */
#navBox::-webkit-scrollbar {
  width: 7px !important;
}

#navBox::-webkit-scrollbar-track {
  background: rgb(219, 211, 211);
  /* background: #f1f1f1; */
}

#navBox::-webkit-scrollbar-thumb {
  /* background: #071aff; */
  border-radius: 100px;
}

#rightHeadBox {
  width: 100%;
  margin-right: 2em;
}

#rightMainBox {
  height: 74vh;
  border: 1.9px solid #071aff;
  width: 100%;
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
}

#leftIconBox {
  width: 38px;
  height: 52px;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 34vh;
  left: -15px;
  z-index: 100;
  background-color: #ffffff;
  border-radius: 3px;
  cursor: pointer;
}

#rightIconBox {
  width: 38px;
  height: 52px;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 34vh;
  right: -15px;
  z-index: 100;
  background-color: #ffffff;
  border-radius: 3px;
  cursor: pointer;
}

#rightContentBox {
  margin-left: 30px;
  margin-right: 30px;
  height: 100%;
  border-radius: 3px;
}

#commentBox {
  display: flex;
  flex-direction: column;
}

#closeIconBox {
  position: absolute;
  top: -7px;
  left: -10px;
  z-index: 100;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #071aff;
  cursor: pointer;
}

#closeIcon {
  font-size: 16px;
  color: white;
}

#buttonBox {
  /* height: 30px; */
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* margin-bottom: 10px; */
  /* border: 1px solid black; */
}

#exitButtonBox {
  width: 55px;
  height: 30px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #f7f7f7;
  border-radius: 4px;
  cursor: pointer;
}

#exitIcon {
  color: #071aff;
  font-size: 18px;
}

#buttonTypo {
  font-size: 14px;
  color: #071aff;
  font-weight: bold;
}

#buttonDivider {
  border-right: 2px solid #707070;
  margin-right: 10px;
  margin-left: 10px;
  height: 100%;
}

#saveDraftButtonBox {
  width: 95px;
  height: 30px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* background-color: #f7f7f7; */
  border-radius: 4px;
  cursor: pointer;
}

#publishButtonBox {
  width: 95px;
  height: 30px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* background-color: #071aff; */
  border-radius: 4px;
  cursor: pointer;
  text-transform: none;
}

#publishBtnTypo {
  font-size: 14px;
  color: #ffffff;
  font-weight: bold;
}

#editButtonBox {
  width: 95px;
  height: 30px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* background-color: #071aff; */
  border-radius: 4px;
  cursor: pointer;
  text-transform: none;
}

#editIcon {
  color: #ffffff;
  font-size: 18px;
}

#editButtonTypo {
  font-size: 14px;
  color: #ffffff;
  font-weight: bold;
}

#shareButtonBox {
  width: 95px;
  height: 30px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* background-color: #071aff; */
  /* border-radius: 4px; */
  position: relative;
}

#shareIcon {
  color: #ffffff;
}

#shareButtonTypo {
  font-size: 14px;
  color: #ffffff;
  font-weight: bold;
}

#playButtonBox {
  width: 95px;
  height: 30px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* background-color: #071aff; */
  border-radius: 4px;
  cursor: pointer;
  text-transform: none;
}

#playIcon {
  color: #ffffff;
}

#playButtonTypo {
  font-size: 14px;
  color: #ffffff;
  font-weight: bold;
}
