#myProfileMainBox {
  margin-left: 30px;
  height: 100%;
  overflow-y: auto;
}

#headBox {
  display: flex;
  align-items: center;
}

#myProfileTypo {
  /* margin-top: 5px; */
  font-weight: bold;
}

#editIconBtn {
  /* margin-top: 5px; */
  margin-left: 5px;
}

#MoreVert {
  font-size: 17px;
  color: #5f5e5e;
}

#deleteIcon {
  font-size: 14px;
  margin-right: 7px;
  margin-left: 7px;
}

#editIcon {
  font-size: 15px;
  color: #707070;
}

#circleIconBox {
  margin-top: 9px;
  margin-left: 8px;
}

#circleIcon {
  color: #071aff;
  font-size: 80px;
}

#nameBox {
  display: flex;
  margin-top: 6px;
}

#firstNameBox {
  width: 30%;
}

#fieldHeadTypo {
  font-size: 14px;
  color: #707070;
  font-weight: bold;
}

#fieldTypo {
  font-size: 14px;
  color: #707070;
}

#profileIcon {
  width: 80px;
  height: 80px;
  border: 1px solid #707070;
  border-radius: 50px;
}

#defaultprofileIcon {
  width: 60px;
  height: 60px;
  border: 1px solid #707070;
  border-radius: 50px;
  padding: 10px;
}

#fieldBox {
  margin-top: 20px;
}

/* profile edit */

#editMainBox {
  margin-left: 30px;
  height: 100%;
  overflow-y: auto;
}

#editHeadBox {
  display: flex;
  align-items: center;
}

#editHeadTypo {
  font-weight: bold;
}

#editCircleIcon {
  width: 80px;
  height: 80px;
}

#editNameBox {
  display: flex;
  width: 100%;
}

#editFieldHeadTypo {
  font-size: 14px;
  color: #707070;
  font-weight: bold;
}

#textFieldBox {
  width: 100%;
  margin-top: 7px;
}

#textFieldBox1 {
  width: 100%;
  margin-top: 2px;
}

#textFieldBox2 {
  width: 100%;
  margin-top: 12px;
}

#editButtonFlexBox {
  display: flex;
  justify-content: flex-end;

  width: 44vw;
}

#editButtonBox {
  margin-top: 7px;
  /* margin-right: 1%; */
}

#accountIconBox {
  margin-top: 7px;
}

#addIcon {
  position: absolute;
  color: #071aff;
  font-size: 15px;
  top: 60px;
  left: 64px;
}

/* @media (min-height: 600px) and (max-height: 690px) {
  #editButtonBox {
    margin-right: 17vw;
  }
} */
