#headBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  width: 100%;
}

#titleTypo {
  font-size: 38px;
  color: #393939;
  font-weight: bold;
}

#iconMainBox {
  display: flex;
}

#iconBox {
  height: 62px;
  width: 62px;
  border: 2px solid #b4b4fa;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#contentBox {
  display: flex;
  justify-content: space-evenly;
  margin-top: 5vh;
  width: 100%;
}

#individualProblemBox {
  /* display: flex;
  align-items: center;
  flex-direction: column;
  height: 52vh;
  width: 30%; */

  display: flex;
  align-items: center;
  flex-direction: column;
  height: 52vh;
  width: 30%;
  row-gap: 2em;
  justify-content: center;
}

#problemTitleBox {
  margin: 2px 0px 2px 0px;
  display: flex;
  align-items: center;
  height: 54px;
  /* height: 40px; */
  width: 100%;
  justify-content: center;
  text-align: center;
}

#titleTypoBox {
  display: flex;
  align-items: center;
  height: 40px;
  justify-content: center;
  width: 100%;
}

#subTitleTypo {
  color: #393939;
  font-weight: bold;
  font-size: 1.3em !important;
}
