#individualPricingMainBox {
  width: 100%;
  margin-top: 20px;
}

#headerMainBox {
  width: 100%;
  display: flex;
}

#revenueStreamTypoMainBox {
  width: 83%;
  border: 1px solid #e1e2e9;
}

#revenueStreamTypoBox {
  display: flex;
  align-items: center;
}

#revenueTypo {
  color: #5f5e5e;
  font-weight: bold;
  margin-left: 20px;
}

#streamDescriptionTypo {
  color: #868686;
  font-size: 14px;
  margin-left: 10px;
  margin-top: 2px;
}

#createPricingButtonBox {
  width: 17%;
  border: 1px solid #071aff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#createPricingButton {
  padding: 2px 0px;
  font-size: 12px;
  text-transform: none;
  color: #071aff;
  width: 100%;
}

#textFieldImgBox {
  width: 100%;
  display: flex;
  margin-top: 3px;
  background-color: #fdfdfd;
}

#textFieldBox {
  width: 85%;
  border: 1px solid #e1e2e9;
  border-right: none;
  box-shadow: 0px 3px 6px #e1e2e943;
}

#mainImgBox {
  width: 15%;
  border: 1px solid #e1e2e9;
  display: flex;
  border-left: none;
}

#imgBox {
  border: 1px solid #e1e2e9;
  width: 95px;
  height: 95px;
  box-shadow: 0px 3px 6px #86868627;
  margin-top: 3px;
  margin-bottom: 3px;
  background-color: #ffffff;
  cursor: pointer;
}

#moreVertIconBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
}
