#gridbox {
  flex-direction: column;
  background: #f8f9ff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 11px;
  padding: 20px 0px;
  margin-top: 8px;
  overflow-x: scroll;
}

#gridbox::-webkit-scrollbar {
  height: 5px;
}
#gridbox::-webkit-scrollbar-track {
  background: #f1f1f1;
}
#gridbox::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 100px;
}

#columntypography {
  position: sticky;
  left: 0px;
  background: #f8f9ff 0% 0% no-repeat padding-box;
  z-index: 5;
  color: #393939;
  flex: 0 0 150px;
  text-align: center;
  font-size: 16px;
}

#othercolumntypographys {
  color: #393939;
  flex: 0 0 35%;
  text-align: center;
  font-size: 16px;
}

#persona {
  cursor: pointer;
  text-align: center;
  flex: 0 0 150px;
  position: sticky;
  left: 0px;
  background: #f8f9ff 0% 0% no-repeat padding-box;
  z-index: 5;
}

#persona:hover {
  background-color: #c7c6c6;
  box-shadow: 0 4px 12px rgb(0 0 0 / 15%);
}

#maingrid {
  overflow-y: auto;
  height: -webkit-fill-available;
  padding: 0px 60px 0px;
}
#maingrid::-webkit-scrollbar {
  width: 5px;
}
#maingrid::-webkit-scrollbar-track {
  background: #f1f1f1;
}
#maingrid::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 100px;
}

#upbtn {
  width: 25px;
  height: 25px;
  position: sticky;
  float: right;
  bottom: 7%;
  right: 0%;
  cursor: pointer;
  margin-left: 15px;
}

/* affinity mapping */
#affinityheading {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  position: sticky;
  top: 0;
  background-color: white;
}

#affinitycontent {
  font-size: 12px;
  line-height: 1.8;
  margin-bottom: 8px;
  white-space: pre-wrap;
}

#affinityimg {
  width: 100%;
}

#scrollleftgrid {
  height: 45vh;
  overflow-y: auto;
}

#scrollleftgrid::-webkit-scrollbar {
  width: 5px;
}
#scrollleftgrid::-webkit-scrollbar-track {
  background: #f1f1f1;
}
#scrollleftgrid::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 100px;
}

#affinitycontainer {
  box-shadow: 3px 3px 6px #b1aaaa31;
  border: 1px solid #cccccc;
  border-radius: 10px;
  padding: 10px 0px;
  justify-content: space-evenly;
  margin-bottom: 10px;
}

#rightgrid {
  display: flex;
  align-items: center;
}

/* sticky notes */

#closeexpandicon {
  float: right;
}

#openexpandicon {
  position: absolute;
  top: 80px;
  right: 50px;
}

/* primary research questions */

#questionnaireHeadBox {
  display: flex;
  justify-content: space-between;
}

#uploadQuestionnaireBox {
  display: flex;
  align-items: center;
}

/* Upload css */
#uploadbox {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 60%;
  justify-content: space-evenly;
  padding: 0 10%;
  border: 1px dashed #707070;
  border-radius: 22px;
  cursor: pointer;
}
#uploadgrid {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  height: 70%;
}
#imgicon {
  transform: matrix(0, -1, 1, 0, 0, 0);
  width: 50px;
  margin-left: 40%;
}

#notesBoardMainGrid{
  overflow-y: hidden;
}

#problemMapCauseAndProbGrid {

  flex: 0 0 35% !important;
  text-align: center;
  
}

@media (max-width: 1024px) {
  #questionnaireHeadBox {
    flex-wrap: wrap;
  }
  #uploadQuestionnaireBox {
    width: 100%;
    justify-content: flex-end;
  }
  /* #maingrid {
    overflow-x: auto;
    width: 1px;
  } */
}
