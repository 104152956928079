#headBox {
  width: 100%;
  height: 10vh;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

#headTypo {
  font-size: 5vh;
  color: #393939;
  font-weight: bold;
}

#headIcon {
  width: 62px;
  height: 62px;
  border: 1px solid #b4b4fa;
  border-radius: 7px;
  position: absolute;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#imgBox {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1vh;
}

#img {
  width: 170px;
  height: 170px;
}

#midHeadBox {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#midHeadTypo {
  font-size: 3vh;
  color: #6b3838;
  font-weight: bold;
}

#contactDetailsBox {
  width: 100%;
  /* display: flex;
  justify-content: center; */
  margin-top: 4vh;
  /* border: 1px solid red; */
}

/* #leftDetails {
  display: flex;
  flex-direction: column;
  width: 70px;
  row-gap: 5px; */
  /* border: 1px solid red; */
  /* margin-left: 34%; */
/* } */

#leftDetailTypo {
  font-size: 2vh;
  color: #083a9a;
  font-weight: bold;
}

#rightDetailBox {
  text-align: left;
  row-gap: 5px;
  width: 100%;
  margin: auto;
}

#rightDetailTypo {
  font-size: 2vh;
  color: #707070;
  margin-left: 20px;
  font-weight: bold;
}
