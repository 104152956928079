#individualTeamBox {
  display: flex;
  width: 23%;
  flex-direction: column;
  align-items: center;
  /* border: 1px solid red; */
}

#editProfileBox {
  width: 50px;
  height: 50px;
  border: 2px dotted #919aff;
  border-radius: 4px;
  position: relative;
}

#profileBox {
  width: 50px;
  height: 50px;
  border: 1px solid #919aff;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#profileIcon {
  font-size: 60px;
  color: #d0d0d0;
}

#nameTitleBox {
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

#nameTypo {
  color: #393939;
  font-weight: bold;
  font-size: 2.5vh;
}

#titleTypo {
  font-size: 2vh;
  color: #707070;
  font-weight: bold;
}

#closeIconBox {
  width: 15px;
  height: 15px;
  border: 1px solid #838383;
  border-radius: 50%;
  z-index: 20;
  position: absolute;
  right: -8px;
  top: -8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}

#closeIcon {
  font-size: 15px;
  color: #838383;
}
