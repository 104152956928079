#IndividualPartnershipMainBox {
  width: 100%;
  height: 55px;
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
  border-radius: 4px;
}

#IndividualPartnershipSubBox {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

#partnershipTextFieldBox {
  width: 25%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #d0d0d0;
}

#endTextFieldBox {
  width: 25%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
