/* the product 2 */

#highlightMainBox {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  /* margin-top: 1vh; */
}

#productHighlight {
  display: flex;
  flex-direction: column;
  width: 30%;
  /* border: 1px solid black; */
}

#highlightTypoBox {
  /* margin-left: 1vw; */
  margin-bottom: 5px;
  /* border: 1px solid black; */
}

#highlightTypo {
  font-size: 1.7vh;
  color: #393939;
  font-weight: bold;
  margin-left: 8px;
}

#imgMainBox {
  width: 100%;
  height: 36vh;
  margin-top: 2.5vh;
  display: flex;
  justify-content: space-between;
}

#Img {
  width: 49.5%;
  height: 99%;
  border-radius: 7px;
  /* background-color: #b4b4fa; */
  border: 1px solid #b4b4fa;

  position: relative;
}

#replaceImgBox {
  position: absolute;
  width: 139px;
  height: 22px;
  left: 22px;
  top: 8px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 5px;
  background-color: #ffffff;
}

#editIcon {
  font-size: 12px;
}

#replaceTypo {
  font-size: 10px;
  color: #393939;
}

/* the product */

#productMainBox {
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#leftContentBox {
  width: 50%;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-evenly;
}

#productTitleTypo {
  margin-bottom: 5px;
  color: #393939;
  font-weight: bold;
  margin-left: 5px;
  font-size: 1.7vh;
}

#productImgBox {
  width: 35%;
  height: 95%;
  border: 1px solid #b4b4fa;
  border-radius: 7px;
  box-shadow: 0px 3px 6px #00000029;
  /* background-color: #b4b4fa; */
  position: relative;
}
