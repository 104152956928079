#compAdvMainBox {
  width: 100%;
  height: 60vh;
  display: flex;
  /* justify-content: space-evenly; */
  column-gap: 1vw;
}

#imgBox {
  width: 25%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid red; */
}

#img {
  width: 295px;
  height: 295px;
  border-radius: 4px;
}

#compStrategyFlex {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

#compTypoBox {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4px;
}

#compTypo {
  font-weight: bold;
  /* margin-bottom: 6px; */
  color: #393939;
  font-weight: bold;
  font-size: 1.7vh;
}
