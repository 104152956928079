#currencyDropdownBox {
  display: flex;
  align-items: center;
  margin-top: 12px;
}

#currencyTypoBox {
  display: flex;
  align-items: center;
}

#currencyTypo {
  margin-right: 15px;
  font-size: 14px;
  font-weight: bold;
}

#box2 {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  /* margin-left: 7vh; */
}

#typography2 {
  font-size: 12px;
  margin-bottom: 20px;
  /* margin-left: 7vh; */
}

#typography3 {
  font-size: 14px;
  /* margin-bottom: 20px; */
  /* margin-left: 7vh; */
  font-weight: bold;
}

.rowHead {
  width: 90px;
  height: 40px;
  font-size: 13px;
  color: "#707070";
}

.rowGridSize {
  width: 50px;
  text-align: "center";
  font-weight: "bold";
  font-size: 12px;
}


#mainGridSize::-webkit-scrollbar {
  width: 5px;
}
#mainGridSize::-webkit-scrollbar-track {
  background: #f1f1f1;
}
#mainGridSize::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 100px;
}
#tableCon::-webkit-scrollbar {
  width: 10px;
}
#tableCon::-webkit-scrollbar-track {
  background: #f1f1f1;
}
#tableCon::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 100px;
}