#griditem1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}
#griditem2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
}
#forgottext {
  font-size: 12px;
  color: #071aff;
  text-transform: capitalize;
  font-weight: bold;
  text-decoration: none;
}
#loginbtn {
  width: 240px;
  height: 38px;
  border-radius: 10px;
  background-color: #071aff;
  margin-left: 19%;
  margin-top: 10px;
  color: white;
  text-transform: capitalize;
}

#signupwithgooglebutton {
  background: #f8f9ff;
  border: 1px solid #cccccc;
  text-transform: none;
  color: #947070;
  font-size: 13px;
  font-weight: 400;
  width: 240px;
  height: 38px;
  border-radius: 5px;
}

#googlebuttonicon {
  width: 16px;
  height: 16px;
  margin: 10px;
  margin-right: 20px;
}

#divider {
  height: 320px;
  margin-left: 10%;
  border-color: #dfd7d7;
}

/* collaboration login */

#invitetypo {
  color: #5f5e5e;
  font-size: 14px;
  text-align: center;
}

#highlightedtext {
  color: #333441;
  font-weight: bold;
}

#gridcontainer {
  justify-content: space-around;
  align-items: center;
  margin-bottom: 7px;
}

#containertypo {
  color: #5f5e5e;
  font-size: 12px;
  font-weight: bold;
  /* border: 1px solid red; */
}

#getstartedbtn {
  text-transform: none;
  border-radius: 4px;

  margin-top: 15px;
  padding: 7px 42px;
}

#mainbox {
  width: 300px;
  text-align: center;
  margin-top: 10px;
}

#boxtypo {
  font-size: 9px;
  color: #5f5e5e;
}

#link {
  color: #071aff;
  text-decoration: underline;
  font-weight: bold;
}

#welcomeTypoBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#welcometypo {
  color: #5f5e5e;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 12vh;
}

#img {
  width: 420px;
  height: 260px;
  padding: 20px 0px 20px 20px;
  /* box-shadow: 0px 3px 6px #00000029; */
}

#signUpTypoBox {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#signUpTypo {
  font-size: 22px;
  color: #5f5e5e;
  font-weight: bold;
}

#haveAnAccountTypoBox {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

#haveAnAccountTypo {
  font-size: 14px;
  color: #5f5e5e;
  font-weight: bold;
}

#loginTypo {
  font-size: 14px;
  color: #071aff;
  font-weight: bold;
  margin-left: 4px;
  text-decoration: underline;
  cursor: pointer;
}

#containertypo2 {
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  /* border: 1px solid red; */
}

#emailtypo {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 3px;
}

#passStrengthGrid {
  position: absolute;
  left: 68%;
  top: 90%;
  margin: 10px;
  padding: 5px;
  width: 130px;
  border: solid 1px #cccccc;
  border-radius: 10px;
}

#strengthTextTypo {
  font-size: 10px;
  margin-bottom: 10px;
}

#orBox {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

#orTypo {
  color: #5f5e5e;
  font-size: 14px;
}

#googleButtonBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

#googleButton {
  background: #f8f9ff;
  border: 1px solid #cccccc;
  text-transform: none;
  color: #947070;
  font-size: 11px;
  font-weight: 400;
  width: 173px;
  height: 35px;
  border-radius: 4px;
}

#googleImg {
  width: 16px;
  height: 16px;
  margin: 10px;
  margin-right: 10px;
}

#troubleLoginBox {
  text-align: center;
  margin-top: 4px;
}

#contactSupportTypo {
  color: #071aff;
  font-weight: bold;
  cursor: pointer;
  font-size: 9px;
}

#BottomDivider {
  width: 70%;
  margin: 10px 0px;
}
