#topTypo {
  color: #5f5e5e;
}

#topSubTypo {
  color: #868686;
  font-size: 13px;
}

#typoTextFieldMainBox {
  width: 100%;
  margin-top: 20px;
  border: 1px solid #ffffff;
}

#individualTypoTextFieldBox {
  width: 100%;
  margin-top: 20px;
}

#individualTypoBox {
  display: flex;
  align-items: center;
  margin-left: 16px;
}

#individualTypo {
  font-size: 14px;
  color: #5f5e5e;
  font-weight: bold;
}

#textFieldBox {
  width: 100%;
  margin-top: 10px;
}

#backNextBox {
  border: 1px solid #ffffff;
}
