#box2 {
  display: flex;
  justify-content: space-between;
}
#typography1 {
  display: inline-flex;
  font-weight: 900;
  color: #393939;
}
#typography2 {
  font-size: 12px;
  margin-bottom: 20px;
  color: #393939;
  font-weight: bold;
}
#gridtypography1 {
  color: #393939;
  font-weight: bold;
}
#griditem {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #071aff1f;
  padding: 20px;
  border-radius: 10px;
  padding-right: 0px;
  position: relative;
  cursor: pointer;
}
#gridnumber {
  border-radius: 50%;
  padding: 3px 12px;
  position: absolute;
  box-shadow: 0px 3px 6px #00000050;
  background: white;
  top: -8%;
  left: -4%;
}
#maingrid {
  overflow-y: auto;
  height: -webkit-fill-available;
  padding: 30px 60px 0px;
}
#maingrid::-webkit-scrollbar {
  width: 5px;
}
#maingrid::-webkit-scrollbar-track {
  background: #f1f1f1;
}
#maingrid::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 100px;
}

#dashboardgrid {
  overflow-y: auto;
  height: -webkit-fill-available;
  padding-left: 30px;
}
#dashboardgrid::-webkit-scrollbar {
  width: 5px;
}
#dashboardgrid::-webkit-scrollbar-track {
  background: #f1f1f1;
}
#dashboardgrid::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 100px;
}

/* final summary css */
#gtmcanvasbtn {
  background: #f8f9ff 0% 0% no-repeat padding-box;
  border: 1px solid #071aff;
  border-radius: 10px;
  color: #071aff;
}
#griditemfinal {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}
#griditem2 {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

#sliderbox {
  border: 1px solid #00132e;
  border-radius: 13px;
  padding: 30px;
  width: -webkit-fill-available;
}

@media (max-width: 1024px) {
  #buyersJourneyQuestion {
    font-size: 12px;
    padding: 11px 9px;
    height: 55px;
  }
  #buyersJourneyRightChevron {
    width: 24px;
    top: 7%;
  }
  #multipleModulesHeadTypo {
    font-size: 13px;
  }
  #multipleModuleIndividualDescription {
    font-size: 13px;
  }
}
