#box2 {
  display: flex;
  justify-content: space-between;
}
#typography1 {
  display: inline-flex;
  align-self: center;
}
#typography2 {
  font-size: 12px;
  margin-bottom: 20px;
  margin-top: 5px;
}
#downloadbtn {
  /* background: #071aff 0% 0% no-repeat padding-box; */
  border-radius: 8px;
  margin-right: 10px;
  box-shadow: none;
  text-transform: capitalize;
  /* width: 220px; */
  height: 35px;
  font-size: 10px;
}
#uploadbtn {
  background: #f0f0f0 0% 0% no-repeat padding-box;
  border: 1px solid #071aff;
  border-radius: 8px;
  color: #071aff;
  box-shadow: none;
  text-transform: none;
  /* width: 220px; */
  height: 35px;
  font-size: 10px;
}
#icon {
  margin-left: 5px;
  font-size: 12px;
  background-color: white;
  border-radius: 50%;
  color: #2c2c2c;
  padding: 1px;
}
#downloadicon {
  background-color: white;
  box-shadow: 0px 1px 6px #071aff26;
  border-radius: 18px;
  margin-left: 10px;
  color: #707070;
}
#grid1 {
  display: flex;
  /* background: #f0f0f0 0% 0% no-repeat padding-box; */
  border-radius: 10px;
  padding: 15px 0px;
  margin-top: 10px;
  flex-wrap: nowrap;
  flex-direction: column;
}
#textarea {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #707070;
  border-radius: 3px;
}
.divider1 {
  border-left: 1px solid #707070;
  margin-right: 5px;
}

#maingrid {
  overflow-y: auto;
  height: -webkit-fill-available;
  padding: 0px 60px 0px;
}
#maingrid::-webkit-scrollbar {
  width: 5px;
}
#maingrid::-webkit-scrollbar-track {
  background: #f1f1f1;
}
#maingrid::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 100px;
}
/* Upload css */
#uploadbox {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 60%;
  justify-content: space-evenly;
  padding: 0 10%;
  border: 1px dashed #707070;
  border-radius: 22px;
  cursor: pointer;
}
#uploadgrid {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  height: 70%;
}
#uploadicon {
  font-size: 50px;
  color: #071aff;
  margin-left: 35%;
}
#imgicon {
  transform: matrix(0, -1, 1, 0, 0, 0);
  width: 50px;
  margin-left: 40%;
}

/* -------------chart content */

#midContentBox {
  height: 100%;
  margin-top: 20px;
}

#currencyDropdownBox {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  /* border: 1px solid #bdc9c1; */
  border-radius: 4px;
  justify-content: flex-start;
}

#currencyTypoBox {
  /* border-right: 1px solid #bdc9c1; */
  height: 45px;
  display: flex;
  align-items: center;
}

#currencyTypo {
  /* margin-right: 15px; */
  margin-left: 15px;
  font-size: 14px;
}

#midContentSubBox {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

#leftFirstBox {
  display: flex;
  flex-direction: column;
  border: 1px solid #bdc9c1;
  border-radius: 4px;
}

#rowBox {
  display: flex;
  align-items: center;
  /* height: 4vw; */
  /* width: 29vw; */
  width: 100%;
}

#typoBoxRow {
  border-right: 1px solid #bdc9c1;
  /* height: 4vw; */
  width: 14vw;
  display: flex;
  align-items: center;
}

#rowTitleTypo {
  margin-left: 6px;
  font-size: 14px;
}

#secondRowBox {
  display: flex;
  align-items: center;
  /* height: 4vw; */
  /* width: 29vw; */
  border-top: 1px solid #bdc9c1;
  width: 100%;
}

#secondRowTypo {
  margin-left: 6px;
  font-size: 14px;
  margin-top: 5px;
}

#keyAssumptionsBox {
  border: 1px solid #bdc9c1;
  margin-top: 20px;
  border-radius: 4px;
}

#keyTypo {
  margin-left: 6px;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
}

#chartBox {
  border: 1px solid #bdc9c1;
  width: 53%;
  border-radius: 4px;
}

#refreshChartTypo {
  margin-left: 6px;
  font-size: 13px;
  margin-top: 10px;
  margin-bottom: 17px;
  color: #071aff;
  text-decoration: underline;
}
