#mainBox {
  height: 58vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

#individualItemBox {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

#circleBox {
  width: 73%;
  display: flex;
  justify-content: center;
  padding-bottom: 73%;
  height: 0%;
}

#addressableMarketCircle {
  width: 100%;
  height: 0%;
  border-radius: 50%;
  background-color: #071aff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 100%;
  position: relative;
}

#circleTypoBox {
  position: absolute;
  top: 43%;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#individualTitleBox {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 7vh;
}

#individualTextBox {
  width: 80%;
}
