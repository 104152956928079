#pricingTopTypo {
  color: #5f5e5e;
}

#pricingSubTypo {
  font-size: 12px;
  color: #868686;
}

#multiSelectMainBox {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}

#individualSelectBox {
  border: 1px solid #fdd3a4;
  height: 95px;
  width: 22%;
  border-radius: 9px;
  cursor: pointer;
}

#individualSelectSubBox {
  margin: 0px 10px;
  height: -webkit-fill-available;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  word-wrap: break-word;
}

#selectBoxTopTypo {
  font-size: 14px;
  color: #5f5e5e;
  font-weight: bold;
}

#selectBoxSubTypo {
  font-size: 12px;
  color: #868686;
}

#secondRowMainBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

#multiselectBottomBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

#textFieldTypoMainBox {
  width: 73.8%;
  display: flex;
  justify-content: space-between;
}

#otherTypoMainBox {
  width: 10%;
  padding-top: 18px;
  display: flex;
}

#otherTypo {
  color: #5f5e5e;
  font-size: 14px;
  font-weight: bold;
}

#textFieldBox {
  width: 90%;
}

#backNextButton {
  border: 1px solid #ffffff;
}
