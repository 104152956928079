#invitedMainBox {
  /* background: white; */
  /* width: 30%; */
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #071aff42;
  margin-top: 3px;
  /* height: 97%; */
  position: relative;
}

#headBox {
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: space-between;
}

#nameTypo {
  font-size: 10px;
  color: #5f5e5e;
  font-weight: bold;
}

#isCommenterBox {
  background-color: #26b99a;
  height: 16px;
  width: 46px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

#commenterTypo {
  font-size: 10px;
  color: white;
}

#editorBox {
  background-color: #6057ff;
  height: 16px;
  width: 65px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

#imageMainBox {
  display: flex;
  justify-content: center;
  margin-top: 3%;
  width: 100%;
  height: 9vh;
  align-items: center;
}

#imgBox {
  height: 48px;
  border: 1px solid #e1e2e9;
  border-radius: 50%;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#ventureBox {
  /* margin-left: 10px;
  margin-top: 2%;
  display: flex;
  justify-content: space-between;
  height: 24%;
  align-items: center; */
  /* margin: auto;
   */
  height: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
}

#newBox {
  height: 18px;
  width: 38.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  box-shadow: 0px 3px 6px #071aff17;
  background-color: #071aff;
}

#MoreVert {
  font-size: 17px;
  color: #5f5e5e;
}

#editIcon {
  font-size: 17px;
  margin-right: 7px;
  margin-left: 7px;
}

#ventureTypo {
  font-size: 14px;
  color: #5f5e5e;
  font-weight: bold;
}

#sharedVenturelogo {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

#imgIcon {
  font-size: 35px;
  color: gray;
}

#tooltipContent {
  background-color: white;
  font-size: 11px;
  color: #ff7f00;
  border: 1px solid #ff7f00;
  padding: 8px;
  border-radius: 10px;
  font-weight: bold;
  position: absolute;
  width: 250px;
  top: 30px;
  left: 150px;
}

/* ----------------------- */

@media (min-width: 600px) and (max-width: 1404px) {
  #ventureTypo {
    font-size: 13px;
  }

  /* #headBox {
    margin-top: 5px;
  } */
}

@media (min-width: 600px) and (max-width: 1369px) {
  /* #headBox {
    margin-top: 5px;
  } */
  #sharedVenturelogo {
    width: 42px;
    height: 42px;
  }

  #imgBox {
    width: 42px;
    height: 42px;
  }

  #imgIcon {
    font-size: 32px;
  }

  #ventureTypo {
    font-size: 12px;
  }

  #nameTypo {
    font-size: 9px;
  }

  #commenterTypo {
    font-size: 10px;
  }
}

@media (min-width: 600px) and (max-width: 1280px) {
  /* #headBox {
    margin-top: 5px;
  } */
  #sharedVenturelogo {
    width: 40px;
    height: 40px;
  }

  #imgBox {
    width: 40px;
    height: 40px;
  }

  #imgIcon {
    font-size: 30px;
  }

  #ventureTypo {
    font-size: 11px;
  }

  #nameTypo {
    font-size: 9px;
  }

  #commenterTypo {
    font-size: 9px;
  }
}
