#milstoneMainGrid {
  height: 100%;
  width: 1px;
  overflow-y: auto;
  overflow-x: auto;
  padding: 40px 50px;
}

#milestoneGraphicalHead {
  font-size: 14px;
  margin-bottom: 20px;
  margin-top: 20px;
}

#othertitles {
  flex: 0 0 300px;
  text-align: center;
}

#tableTypographyHead {
  font-size: 14px;
  font-weight: bold;
  color: #868686;
}

#tableTypographyHeadFirst {
  font-size: 14px;
  font-weight: bold;
  color: #868686;
  /* margin-left: 36px; */
}

#removeCircleIconRow {
  margin-right: 10px;
  color: #ff7f5c;
}

#tableCell {
  padding: 10px 20px;
  /* display: flex;
    align-items: center; */
}

#tableCellFirst {
  padding: 10px 20px;
}

#tableCellChildFirst {
  padding: 10px 20px;
}

#tableCellLast {
  padding: 10px 20px;
  display: flex;
  align-items: center;
}

#tableCellChildLast {
  padding: 10px 20px;
  display: flex;
  align-items: center;
}

#addActivityButton {
  background-color: #ffffff;
  margin-top: 10px;
  text-transform: none;
  color: #071aff;
  font-weight: bold;
  margin-left: 40px;
  font-size: 10px;
}

#disabledActivityButton {
  background-color: #ffffff;
  margin-top: 10px;
  text-transform: none;
  color: #b0b0b0;
  font-weight: bold;
  margin-left: 40px;
  font-size: 10px;
}

#delActivityButton {
  background-color: #ffffff;
  margin-top: 10px;
  text-transform: none;
  color: #ff7f5c;
  font-weight: bold;
  margin-left: 20px;
  font-size: 10px;
}

#disableddelActivityButton {
  background-color: #ffffff;
  margin-top: 10px;
  text-transform: none;
  color: #b0b0b0;
  font-weight: bold;
  margin-left: 20px;
  font-size: 10px;
  cursor: default;
}

#addMilestoneButton {
  background-color: #ffffff;
  margin-top: 10px;
  text-transform: none;
  /* border: 1px solid #071aff; */
  color: #071aff;
  font-weight: bold;
  margin-left: 20px;
  /* font-size: 14px; */
}

#disabledaddMilestoneButton {
  background-color: #ffffff;
  margin-top: 10px;
  text-transform: none;
  /* border: 1px solid #071aff; */
  color: #b0b0b0;
  font-weight: bold;
  margin-left: 20px;
  /* font-size: 14px; */
}

#delMilestoneButton {
  background-color: #ffffff;
  margin-top: 10px;
  text-transform: none;
  border: 1px solid #ff7f5c;
  color: #ff7f5c;
  font-weight: bold;
  margin-left: 20px;
  font-size: 14px;
}

#disableddelMilestoneButton {
  background-color: #ffffff;
  margin-top: 10px;
  text-transform: none;
  border: 1px solid #b0b0b0;
  color: #b0b0b0;
  font-weight: bold;
  margin-left: 20px;
  font-size: 14px;
  cursor: default;
}

#tableBox {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 7px;
  margin-top: 20px;
}

/* #tableRowHead {
    border-bottom: 1px solid #b1aaaa;
  } */

#tableTypographyHead2 {
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  color: #868686;
}

#buttonBox {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

#milestoneAddIcon {
  /* margin-right: 10px; */
  font-size: 35px;
}

#milestoneRemoveIcon {
  margin-right: 10px;
}

#graphicalViewButtonGrid {
  height: 30%;
  margin-bottom: 30px;
}

#dialogOffIcon {
  align-self: flex-end;
}

#milestoneTableBody {
  /* box-shadow: 0px 3px 6px #00000029; */
  border-radius: 7px;
  /* border: 1px solid #00000029; */
}

#milestoneTableRow {
  /* box-shadow: 0px 0px 3px #00000029; */
  /* border-bottom: 1px solid #b1aaaa; */
  border-radius: 7px;
  border-top: 1px solid #00000029;
  border-bottom: 1px solid #00000029;
}

#activityTableRow {
  /* border-top: 1px solid #b1aaaa; */
  border-bottom: 1px solid #00000029;
}
