#bizOpsMainGrid {
  height: 100%;
  overflow-y: auto;
  padding: 0px 60px 0px;
}


#bizOpsMainGrid::-webkit-scrollbar {
  width: 5px;
}
#bizOpsMainGrid::-webkit-scrollbar-track {
  background: #f1f1f1;
}
#bizOpsMainGrid::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 100px;
}

#titleTypo {
  padding-left: 20px;
  /* font-size: 14px; */
}

#addRowButton {
  background-color: #ffffff;
  margin-top: 10px;
  text-transform: none;
  /* border: 1px solid #071aff; */
  color: #071aff;
  font-weight: bold;
  /* margin-left: 20px; */
  font-size: 14px;
}

#disabledaddRowButton {
  background-color: #ffffff;
  margin-top: 10px;
  text-transform: none;
  /* border: 1px solid #071aff; */
  color: #b0b0b0;
  font-weight: bold;
  /* margin-left: 20px; */
  font-size: 14px;
}

#delRowButton {
  background-color: #ffffff;
  margin-top: 10px;
  text-transform: none;
  border: 1px solid #ff7f5c;
  color: #ff7f5c;
  font-weight: bold;
  margin-left: 20px;
  font-size: 14px;
}

#disableddelRowButton {
  background-color: #ffffff;
  margin-top: 10px;
  text-transform: none;
  border: 1px solid #b0b0b0;
  color: #b0b0b0;
  font-weight: bold;
  margin-left: 20px;
  font-size: 14px;
  cursor: default;
}
