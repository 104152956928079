#mainBox {
  height: 60vh;
  width: 100%;
  display: flex;
  /* justify-content: space-evenly; */
}

#imgbox {
  width: 35%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid black; */
}

#img {
  width: 270px;
  height: 270px;
}

#rightContentBox {
  width: 65%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* border: 1px solid black; */
}

#textBoxTitle {
  color: #393939;
  font-weight: bold;
  margin-bottom: 3px;
  margin-left: 7px;
  margin-top: 3px;
  font-size: 2vh;
}
