#priceDiscriminationTypo {
  color: #5f5e5e;
  font-size: 16px;
}

#typoImgMainBox {
  width: 100%;
  display: flex;
}

#descTypoBox {
  width: 65%;
}

#descTypo {
  font-size: 13px;
  color: #868686;
}

#mainImgBox {
  width: 35%;
  height: 14vh;
  position: relative;
}

#imgBox {
  position: absolute;
  top: -25px;
  left: 110px;
}

#img {
  width: 129px;
  height: 143px;
}

#textFieldBox {
  width: 100%;
  margin-top: 3rem;
}
