.fc .fc-button-primary {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  color: #393939;
}
.fc .fc-button-primary:disabled {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  color: #393939;
}
.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #071aff;
  border-radius: 5px;
  color: #393939;
}
.fc .fc-button-primary:not(:disabled):active:focus,
.fc .fc-button-primary:not(:disabled).fc-button-active:focus {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #071aff;
  border-radius: 5px;
  color: #393939;
}

.fc .fc-button-primary:hover {
  background-color: white;
  color: #393939;
  border: 1px solid #071aff;
}
.fc-prev-button {
  background-color: #f7f7f7 !important;
  border: none !important;
}
.fc-next-button {
  background-color: #f7f7f7 !important;
  border: none !important;
}
.fc .fc-button-group > .fc-button:focus {
  background-color: #f7f7f7 !important;
  border: none !important;
}

.fc-list-event-graphic {
  display: none;
}

.fc-daygrid-day {
  cursor: pointer;
}
