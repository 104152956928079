/* dashboard head */

#headBox {
  /* margin-top: 3vh; */
  /* margin-left: 100px; */
  /* margin-right: 11px; */
  width: 98%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.divider {
  border-left: 1px solid black;
  margin-right: 10px;
  height: 30px;
}

#headBoxItem {
  display: flex;
  align-items: center;
}

#typoWrapper {
  font-weight: bold;
  display: flex;
  align-items: center;
  cursor: pointer;
}

#inviteicon {
  margin-right: 10px;
}

#lockIconBox {
  margin-left: 4px;
  display: flex;
  align-items: center;
  margin-right: 4px;
}

#dividerBox {
  display: flex;
  align-items: center;
}

#typoHead {
  margin-left: 10px;
  margin-right: 20px;
  font-weight: bold;
  color: #071aff;
}

#typoComp {
  margin-left: 10px;
  margin-right: 20px;
  font-weight: bold;
}

/* grid section */

/* #gridMainBox { */
  /* margin: 14px 100px 20px 100px; */
/* } */

/* module box */

#mainGridModulesBox {
  display: flex;
  justify-content: space-evenly;
  margin-top: 16px;
}

#gridFirstRow {
  display: flex;
  justify-content: space-between;
}

#secondRowGrid {
  display: flex;
  justify-content: space-between;
}

#typoOverlay {
  font-size: 20px;
  font-weight: bold;
}

#subOverlay {
  font-size: 13px;
  font-weight: bold;
}

#thirdRowGrid {
  display: flex;
  justify-content: space-between;
}

/* firstRowBox.js */

#moduleMainBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 22vh;
}

#titleBoxModule {
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
}

#BottomBoxModule {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

#sliderBox {
  width: 48%;
  margin-left: 15px;
  display: flex;
  align-items: center;
}

#buttonBox {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

#lockIconBox {
  margin-left: 5px;
  display: flex;
  align-items: center;
}

/* row box */

.firstRowBox {
  border: 1px solid #071aff;
  border-radius: 8px;
  /* height: 135px; */
  width: 24%;
  background-color: white;
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */
  box-shadow: 0px 5px 6px #071aff21;
  cursor: pointer;
}

.firstRowBox:hover {
  background-color: #f3ecff;
  border: 1px solid #f3ecff;
}

.secondRowBox {
  border: 1px solid #071aff;
  border-radius: 8px;
  /* height: 135px; */
  width: 32%;
  background-color: white;

  box-shadow: 0px 5px 6px #071aff21;
  cursor: pointer;
}

.secondRowBox:hover {
  background-color: #e2ece9;
  border: 1px solid #e2ece9;
}

.thirdRowBox {
  border: 1px solid #071aff;
  border-radius: 8px;
  /* height: 135px; */
  width: 49%;
  background-color: white;

  box-shadow: 0px 5px 6px #071aff21;
  cursor: pointer;
}

.thirdRowBox:hover {
  background-color: #ffede5;
  border: 1px solid #ffede5;
}

/* hover styles */

.firsthoverBox {
  border: 1px solid #071aff;
  border-radius: 8px;
  /* height: 135px; */
  width: 24%;
  background-color: white;

  box-shadow: 0px 5px 6px #071aff21;
  position: relative;
}

.firsthoverBox:hover {
  border: 1px solid #f3ecff;
}

.firstoverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f3ecff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.25s;
}

.firstoverlay>* {
  transform: translateY(20px);
  transition: transform 0.25s;
}

.firstoverlay:hover {
  opacity: 1;
}

.firstoverlay:hover>* {
  transform: translateY(0);
}

/* for second row */

.hoverBox {
  border: 1px solid #071aff;
  border-radius: 8px;
  /* height: 135px; */
  width: 32%;
  background-color: white;

  box-shadow: 0px 5px 6px #071aff21;
  position: relative;
  cursor: pointer;
}

.hoverBox:hover {
  border: 1px solid #e2ece9;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #e2ece9;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.25s;
}

.overlay>* {
  transform: translateY(20px);
  transition: transform 0.25s;
}

.overlay:hover {
  opacity: 1;
}

.overlay:hover>* {
  transform: translateY(0);
}

/* --------------------- */

/* for pitch deck */

.hoverBoxPitch {
  border: 1px solid #071aff;
  border-radius: 8px;
  /* height: 135px; */
  width: 49%;
  background-color: white;

  box-shadow: 0px 5px 6px #071aff21;
  position: relative;
}

.hoverBoxPitch:hover {
  border: 1px solid #ffede5;
}

.overlayPitch {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffede5;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.25s;
}

.overlayPitch>* {
  transform: translateY(20px);
  transition: transform 0.25s;
}

.overlayPitch:hover {
  opacity: 1;
}

.overlayPitch:hover>* {
  transform: translateY(0);
}

#projectNotesDialog {
  padding: 30px;
  height: 600px;
}

#viewBizSummaryDialog {
  padding: 0px;
  height: 100%;
}

#projectNotesText {
  font-weight: bold;
  font-size: 16px;
}

#textFieldBox {
  display: flex;
  justify-content: space-between;
}

#imgIcon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

#postBtns {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

#previousNotesBox {
  margin: 10px 0px;
  height: 280px;
  overflow-y: auto;
  padding-right: 10px
}

#previousNotesBox::-webkit-scrollbar {
  width: 5px;
}

#previousNotesBox::-webkit-scrollbar-track {
  background: #f1f1f1;
}

#previousNotesBox::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 100px;
}


#commentBox {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
}

#commentDetails {
  display: flex;
  justify-content: space-between;
}

#deleteText {
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  color: #5F5E5E
}

#editText {
  font-size: 14px;
  font-weight: bold;
  margin-right: 20px;
  cursor: pointer;
  color: #5F5E5E
}

#commentBtns {
  display: flex;
  justify-content: flex-start;
  margin-top: 10px
}

#commentText {
  font-size: 14px;
  margin: 5px 0px
}

#postedText {
  font-weight: bold;
  font-size: 13px
}

#dateText {
  font-weight: normal;
  font-size: 12px
}

#defaultProfile {
  font-size: 55px;
  color: #707070
}