#maingrid {
  overflow-y: scroll;
  height: -webkit-fill-available;
  padding-left: 25px;
}

#maingrid::-webkit-scrollbar {
  width: 5px;
}

#maingrid::-webkit-scrollbar-track {
  background: #f1f1f1;
}

#maingrid::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 100px;
}

#maingrid2 {
  overflow-y: auto;
  min-height: 64%;
  max-height: 64%;
  padding: 0px 60px 0px;
}
#maingrid2::-webkit-scrollbar {
  width: 5px;
}
#maingrid2::-webkit-scrollbar-track {
  background: #f1f1f1;
}
#maingrid2::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 100px;
}

#gridContainer {
  margin: 2rem 0px;
}

#textfieldspan {
  border-right: solid 1px #cccccc;
  height: 30px;
  margin-right: 30px;
}

#segmentmainbox {
  display: flex;
  flex-direction: column;
  height: 60%;
}

#segmentbox {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

#groupimgs {
  /* width: 50px;
  height: 70px; */

  width: 60px;
  height: 60px;

  cursor: pointer;
}

#groupimgs:hover {
  background-color: #c7c6c6;
  box-shadow: 0 4px 12px rgb(0 0 0 / 15%);
}

/* segment personas 2 css */
#gridcontainer {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-radius: 15px;
  padding: 10px;
  align-items: center;
  margin-bottom: 15px;
  position: relative;
}

/* ------ */
#gridcontainer1 {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-radius: 15px;
  padding: 10px;
  align-items: center;
  margin-bottom: 15px;
  position: relative;
  width: 99%;
  /* width: 45%; */
}

/* ---------- */

#persona {
  cursor: pointer;
  text-align: center;
  flex: 0 0 80px;
  padding: 10px 0px;
  position: relative;
}

#personagrid {
  overflow-x: auto;
  display: flex;
  /* justify-content: space-around; */
  border-left: solid 2px #cccccc;
  border-right: solid 2px #cccccc;
}

#personagrid::-webkit-scrollbar {
  height: 5px;
}

#personagrid::-webkit-scrollbar-track {
  background: #f1f1f1;
}

#personagrid::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 100px;
}

/* ---- */
#personagridDialog {
  overflow-x: auto;
  display: flex;
  /* justify-content: flex-start; */
  border-left: solid 2px #cccccc;
  /* border-right: solid 2px #cccccc; */
  padding: 0px 20px;
}

#personagrid1::-webkit-scrollbar {
  height: 5px;
}

#personagrid1::-webkit-scrollbar-track {
  background: #f1f1f1;
}

#personagrid1::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 100px;
}

/* ----- */
#persona:hover {
  background-color: #c7c6c6;
  box-shadow: 0 4px 12px rgb(0 0 0 / 15%);
}

/* user persona details css */

#problemSolutionbox {
  height: 30vh;
  /* overflow-y: auto; */
}

/* #problemSolutionbox::-webkit-scrollbar {
  width: 5px;
}

#problemSolutionbox::-webkit-scrollbar-track {
  background: #f1f1f1;
}

#problemSolutionbox::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 100px;
} */

#personadownloadbtn {
  background: #f8f9ff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #071aff;
  border-radius: 6px;
  color: #071aff;
  width: 130px;
  height: 35px;
}

#detailsbox {
  text-align: center;
  padding: 10px;
  border-bottom: solid 1px #b1aaaa;
}

#detailstypography1 {
  font-size: 0.7em;
  font-weight: bold;
  color: #393939;
}

#detailsgriditem1 {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #f0f0f0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#individualheading {
  font-weight: bold;
  color: #393939;
}

#individualanswer {
  font-size: 12px;
}

#individualquestion {
  color: #393939;
  font-weight: bold;
  font-size: 14px;
}

#individualexpand {
  width: 20px;
  font-weight: bold;
  align-self: flex-end;
  margin: 10px;
  cursor: pointer;
}

#individualgrid {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #f0f0f0;
  padding: 20px;
}

#individualtitle {
  font-weight: bold;
  text-align: center;
}

#editbtn {
  color: #071aff;
  border-color: #071aff;
}

/* segmentsPersonas2 */

#addPersona {
  color: #071aff;
  margin-left: 10px;
}

/* user personas */
#avatarImg {
  /* width: 110px;
  height: 140px; */
  width: 178px;
  height: 177px;
}

#avatarGrid {
  display: flex;
  align-items: center;
}

#midContentGrid {
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  overflow-y: auto;
}

#midContentGridIndidual {
  height: 85%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  overflow-y: auto;
}

#mobileViewFontSize {
  font-size: 12px;
}

/* other details */

@media (min-width: 300px) and (max-width: 667px) {

  #mobileViewFontSize {
    font-size: 12px;
  }

 }

@media (max-width: 1024px) {
  #gridcontainer {
    width: 95%;
  }

  #addPersona {
    margin-left: 8px;
  }

  #avatarImg {
    /* width: 70px;
    height: 128px; */
    width: 150px;
    height: 150px;
  }

  /* #groupimgs {
    width: 37px;
    height: 67px;
  } */
  #groupimgs {
    width: 60px;
    height: 60px;
  }

  #questionsGridItem {
    max-width: 40.66%;
  }
}

#grpImgBox {
  display: flex;
  justify-content: space-evenly;
  width: 417px;
}

/* ---------- */

@media (min-height: 600px) and (max-height: 767px) {
  #midContentGrid {
    height: 70%;
  }
}

@media (max-height: 762px) {
  #avatarImg {
    /* width: 90px;
    height: 117px; */
    width: 120px;
    height: 120px;
  }

  /* #groupimgs {
    width: 38px;
    height: 57px;
  } */
  #groupimgs {
    width: 50px;
    height: 50px;
    margin-right: 5px;
  }

  #groupimgs1 {
    width: 95px;
    height: 97px;
  }

  #grpImgBox {
    width: 380px;
  }
}