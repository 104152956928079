#maingrid {
  overflow-y: auto;
  height: 100%;
  width: 100%;
  padding: 0px 60px;
  padding-bottom: 0px;
}
#maingrid::-webkit-scrollbar {
  width: 5px;
}
#maingrid::-webkit-scrollbar-track {
  background: #f1f1f1;
}
#maingrid::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 100px;
}

#labeltext {
  color: #707070;
}
.logoimg {
  width: 100px;
  height: 93px;
}
#uploadtext {
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 8pt;
  color: blue;
}
