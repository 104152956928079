#img {
  width: 400px;
  height: 250px;
  padding: 20px;
  box-shadow: 0px 3px 6px #00000029;
}
#griditem1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}
#griditem2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#btn {
  width: 200px;
  height: 38px;
  border-radius: 10px;
  background-color: #071aff;
  margin-left: 24%;
  margin-top: 20px;
  color: white;
  text-transform: capitalize;
  margin-bottom: 10px;
}

#signupwithgooglebutton {
  color: #947070;
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  height: 38px;
  text-transform: capitalize;
}

#googlebuttonicon {
  width: 1.46vw;
  height: 1.46vw;
  margin: 10px;
}

/* Register Details css */
#maingrid {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding: 20px 50px;
  height: 100%;
}
#detailsgrid1 {
  display: flex;
  flex-direction: column;
}
#detailsgrid2 {
  display: flex;
  flex-direction: row;
  position: relative;
}

#detailsgriditem {
  display: flex;
  flex-direction: column;
}
#labeltext {
  font-size: 13px;
  color: #707070;
  margin-bottom: 5px;
}

/* Resend Link Component */
#mainbox {
  height: 75vh;
  border: solid 1px grey;
  margin: 40px 20%;
  border-radius: 5px;
  overflow-y: auto;
  background-color: #ffffff;
}
#dialogmaingrid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
#backbtn {
  text-transform: none;
  font-weight: bold;
  padding: 0px;
  margin-bottom: 20px;
}
#dialogbtn {
  width: 100%;
  height: 45px;
  border-radius: 10px;
  background-color: #071aff;
  margin-top: 30px;
  color: white;
  text-transform: capitalize;
  font-weight: bold;
}
