#topTypoBox {
  display: flex;
  align-items: center;
}

#topTypo {
  color: #5f5e5e;
  font-weight: bold;
}

#typoImgbox {
  width: 100%;
  display: flex;
}

#businessGoalDescBox {
  width: 60%;
  display: flex;
  justify-content: center;
}

#businessGoalTypo {
  color: #868686;
  font-size: 12px;
}

#mainImgBox {
  width: 40%;
  height: 54px;
  /* height: 96px; */
  display: flex;
  position: relative;
}

#imgBox {
  margin-left: 20%;
  position: absolute;
  top: -53px;
  /* top: -24px; */
  left: 20px;
  /* left: 0px; */
  /* border: 1px solid black; */
}

#img {
  width: 120px;
  height: 95px;
}

#textFieldBox {
  margin-top: 5vh;
}
