#headTypo {
  color: #5f5e5e;
}

#subTypo {
  font-size: 12px;
  color: #868686;
}

#typoImgFlex {
  width: 100%;
  display: flex;
  margin-top: 15px;
}

#flexTypo {
  width: 70%;
  font-size: 12px;
  color: #868686;
}

#blankImgBox {
  width: 30%;
  /* border: 1px solid black; */
}

#textfieldImgBox {
  width: 100%;
  margin-top: 15px;
  display: flex;
}

#textFieldMainBox {
  width: 60%;
  display: flex;
  align-items: center;
}

#textFieldBox {
  width: 100%;
}

#imgTypoFlex {
  width: 40%;
}

#imgBox {
  height: 80px;
  position: relative;
  /* border: 1px solid black; */
}

#img {
  width: 170px;
  height: 153px;
  /* border: 1px solid black; */
  /* background-color: red; */
  position: absolute;
  right: 30px;
  top: -80px;
}

#mainTypoBoxImgflex {
  padding: 10px;
}

#typoBoxImgFlex {
  font-size: 12px;
}

#somePointersTypo {
  color: #5f5e5e;
  font-weight: bold;
  margin-right: 4px;
}

#dialogTopTypo {
  font-size: 14px;
  color: #ffffff;
}

#dialogTypoSpan {
  font-weight: bold;
}

#dialogBottomTypoBox {
  margin-top: 20px;
}

#dialogBottomTypo {
  font-size: 14px;
  color: #ffffff;
}

#backNextButtonBox {
  border: 1px solid #ffffff;
}

@media (max-width: 1076px) {
  #img {
    right: 14px;
  }
}
