#mainbox {
  height: 75vh;
  margin: 40px 20%;
  border-radius: 5px;
  overflow-y: auto;
  background-color: #ffffff;
}

#maingrid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
#backbtn {
  text-transform: none;
  font-weight: bold;
  padding: 0px;
  margin-bottom: 20px;
  color: #071aff;
  cursor: pointer;
}

#btn {
  width: 100%;
  height: 45px;
  border-radius: 10px;
  background-color: #071aff;
  margin-top: 20px;
  color: white;
  text-transform: capitalize;
}

#forgotPassTypo {
  font-weight: bold;
  margin-bottom: 16px;
  font-size: 20px;
}

#linkTypo {
  font-size: 14px;
  margin-top: 20px;
}
