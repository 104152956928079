#topTypo {
  color: #5f5e5e;
}

#typoImgMainBox {
  width: 100%;
  display: flex;
}

#typoBox {
  width: 70%;
}

#descTypo {
  color: #868686;
  font-size: 13px;
}

#imgBox {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#img {
  width: 122px;
  height: 79px;
}

#textFieldBox {
  width: 100%;
  margin-top: 3rem;
}
