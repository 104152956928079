#H6 {
  margin: 0px;
  height: 36px;
  font: normal normal bold 16px/17px Helvetica Neue;
  letter-spacing: 0.16px;
  color: #707070;
  opacity: 1;
  cursor: pointer;
}

#upgradebtn {
  width: 111px;
  height: 32px;
  background: #071aff 0% 0% no-repeat padding-box;
  border-radius: 23px;
  opacity: 1;
  margin-right: 26px;
  text-transform: capitalize;
}

#outlinedbasic {
  padding-top: 4px;
  padding-bottom: 4px;
}

#navbar {
  box-shadow: none;
  border-bottom: 1px solid rgb(97, 92, 92);
  background-color: white;
  height: 9vh;
  justify-content: center !important;
}

.divider1 {
  border-left: 2px solid rgb(97, 92, 92);
  margin-left: 30px;
}

#titlename {
  height: 28px;
  text-align: left;
  font: normal normal medium 20px/30px;
  letter-spacing: 0px;
  color: #071aff;
  opacity: 1;
  margin-left: 30px;
  font-weight: bold;
  cursor: pointer;
  text-transform: capitalize;
}
