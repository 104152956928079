#mainBox {
  height: 80vh;
  margin: 20px 100px;
  box-shadow: 0px 3px 16px #00000029;
  border-radius: 10px;
  display: flex;
  background-color: #ffffff;
}

#leftBox {
  width: 40%;
  background-color: #8089ff12;
  border-radius: 10px 0px 0px 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#leftTypoBox {
  margin-left: 25px;
  margin-top: 10px;
}

#leftHeadtypo {
  font-size: 40px;
  font-weight: bold;
  color: #464646;
}

#rightBox {
  width: 60%;
  height: 100%;
  overflow-y: auto;
}

#mainContentBox {
  margin-left: 25px;
  margin-right: 25px;
}

#headTypoBox {
  margin-top: 20px;
}

#headTypo {
  font-weight: bold;
  color: #5f5e5e;
}

#subHeadTypoBox {
  display: flex;
  align-items: center;
  margin: 15px 0px;
}

#subHeadTypo {
  font-weight: bold;
  color: #5f5e5e;
  font-size: 12px;
}

#hyperlinkTypoBox {
  margin: 0px 4px;
}

#hyperlinkTypo {
  font-size: 12px;
  font-weight: bold;
  color: #4351ff;
  cursor: pointer;
}

#nameEmailBox {
  display: flex;
}

#nameBox {
  margin-right: 12%;
  width: 50%;
  display: flex;
  align-items: center;
}

#nameTypoBox {
  margin-right: 17px;
}

#nameTypo {
  font-size: 12px;
  font-weight: bold;
}

#emailBox {
  width: 50%;
  display: flex;
  align-items: center;
}

#emailTypoBox {
  margin-right: 17px;
}

#emailTypo {
  font-size: 12px;
  font-weight: bold;
}

#expBox {
  margin-top: 30px;
}

#expTypo {
  font-size: 13px;
  font-weight: bold;
}

#emojiBox {
  margin-top: 10px;
  border-radius: 4px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 20px 0px;
}

#checkedemoji {
  background-color: #707070;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#mindBox {
  margin-top: 30px;
}

#mindTypo {
  font-weight: bold;
  font-size: 13px;
}

#recommendBox {
  margin-top: 30px;
  margin-bottom: 10px;
}

#recommendHeadTypo {
  font-weight: bold;
  font-size: 13px;
}
#recommendText {
  color: #071aff;
  font-weight: bold;
  font-size: 14px;
}

#ratingBox {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  padding: 20px 0px;
}

#numberBox {
  border: 1px solid #071aff;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}

#Btn {
  border-radius: 50%;
  border: none;
  background-color: white;
  cursor: pointer;
}

#numTypo {
  font-size: 15px;
  font-weight: bold;
}

#submitCancelBox {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
}

#submitBox {
  margin-right: 20px;
}

#leftImg {
  height: 337px;
  width: 337px;
}

#emojiImg {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

#submitButton {
  font-size: 12px;
}

#cancelButton {
  font-size: 12px;
}

#numbergroup {
  display: flex;
  justify-content: space-evenly;
}

#recommendationtext {
  display: flex;
  justify-content: space-between;
  margin: 5px 15px;
}

/* support */

#supportLeftHeadTypo {
  font-size: 40px;
  font-weight: bold;
  color: #464646;
}

#hyperlinkTypoSupport {
  font-size: 12px;
  font-weight: bold;
  color: #4351ff;
  text-decoration: underline;
  cursor: pointer;
}

#forHelpBox {
  margin-top: 30px;
}

#forHelpTypo {
  font-size: 13px;
  font-weight: bold;
  color: #5f5e5e;
}

#subjectBox {
  margin-top: 20px;
}

#subjectTypo {
  font-weight: bold;
  font-size: 13px;
}

#textInputBox {
  margin-top: 10px;
}

#msgBox {
  margin-top: 20px;
}

#msgTypo {
  font-weight: bold;
  font-size: 13px;
}

#attachmentBox {
  margin-top: 20px;
}

#attachmentTypo {
  font-size: 14px;
  font-weight: bold;
}

#chooseFileBox {
  margin: 10px 0px;
  display: flex;
}

#chooseFileBtn {
  font-size: 12px;
  text-transform: none;
  font-weight: bold;
}

#chooseFileSubTypo {
  font-weight: bold;
  font-size: 10px;
}

@media (min-height: 628px) and (max-height: 767px) {
  #leftImg {
    height: 277px;
    width: 277px;
  }
  #leftHeadtypo {
    font-size: 35px;
  }
  #supportLeftHeadTypo {
    font-size: 35px;
  }
}
