#mainBox {
  width: 100%;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border: 1px solid black; */
  /* position: relative; */
}

#individualRevenueBox {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  /* border: 1px solid black; */
}

#revenueTypoBox {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  width: 90%;
  /* border: 1px solid black; */
}

#revenueTypo {
  color: #393939;
  font-weight: bold;
  font-size: 1.1em;
}

#textFieldbox {
  width: 90%;
}
