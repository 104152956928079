#monetisationMainGrid {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: auto;
  padding: 0px 60px 0px 60px;
}

#topBarMainBox {
  height: 20px;
  width: 100%;
  display: flex;
  column-gap: 5%;
}

#bar {
  width: 10%;
  height: 0px;
  border-radius: 8px;
}
