#btn {
  width: 100%;
  height: 45px;
  border-radius: 10px;
  background-color: #071aff;
  margin-top: 20px;
  color: white;
  text-transform: capitalize;
}
#mainbox {
  height: 75vh;
  margin: 40px 20%;
  border-radius: 5px;
  overflow-y: auto;
  background-color: #ffffff;
}
#maingrid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
#backbtn {
  text-transform: none;
  font-weight: bold;
  padding: 0px;
  margin-bottom: 20px;
  color: #071aff;
}
/* Reset Password */
#maingrid2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80%;
}
#endtext {
  font-size: 14px;
  margin: 0px 20%;
}
