#title {
  font-size: 16px;
  font-weight: bold;
  /* height: 50px; */
  height: 50px;
  /* border: 1px solid red; */
  text-align: center;
}
#icon {
  font-size: 16px;
  color: #071aff;
}
#highlightofficon {
  fill: grey;
}
#image {
  width: 70%;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #f0f0f0;
  margin-bottom: 10px;
  cursor: pointer;
  /* margin-top: 10px; */
}
#individualbox {
  border: solid 1px #707070;
  border-radius: 6px;
  /* padding: 30px 40px; */
  padding: 15px 25px;
  position: relative;
  /* padding-right: 0px; */
  /* margin-top: 10px; */
}
#gridnumber {
  border-radius: 50%;
  position: absolute;
  padding: 4px 15px;
  font-size: 16px;
  box-shadow: 0px 3px 6px #00000050;
  opacity: 1;
  top: -5%;
  left: -4%;
  background-color: white;
}
#arrowgrid {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#arrowimg {
  width: -webkit-fill-available;
}
#rowarrowimg {
  width: 500px;
  margin-left: 200px;
  height: 100%;
}

#maingrid {
  overflow-y: auto;
  height: -webkit-fill-available;
  padding: 30px 60px 0px;
}
#maingrid::-webkit-scrollbar {
  width: 5px;
}
#maingrid::-webkit-scrollbar-track {
  background: #f1f1f1;
}
#maingrid::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 100px;
}

/* progress images */
#progressimg {
  width: 100%;
  height: 70%;
}

/* arrow grid */

@media (max-width: 1024px) {
  #arrowgrid {
    max-width: 7%;
  }
  #contentGrid {
    justify-content: center;
  }
  #rowarrowimg {
    margin-left: 120px;
    width: 50%;
  }
}

@media (max-height: 688px) {
  #individualbox {
    padding: 9px 25px 3px;
  }
  #title {
    height: 30px;
  }
}
