#mainBox {
  height: 75vh;
  background-color: #ffffff;
  border: 1px solid #707070;
  margin: 40px 100px;
  border-radius: 10px;
  overflow-y: auto;
}

#headerGrid {
  display: flex;
  justify-content: space-between;
  margin-top: 44px;
  margin-right: 38px;
  margin-left: 73px;
  align-items: center;
}

#headTypo {
  color: #707070;
  font-weight: bold;
  font-size: 20px;
}

#subHeadTypo {
  color: #686868;
  font-size: 16px;
}

#dashboardButton {
  background-color: #ffffff;
  border: 1px solid #071aff;
  border-radius: 10px;
  text-transform: none;
}

#buttonTypo {
  font-size: 18px;
  padding: 12px 36px;
  color: #071aff;
}

#emailGridItem {
  display: flex;
  justify-content: center;
}

#textInputBox {
  display: flex;
  align-items: flex-end;
}

#radioButtonBox {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 10px;
}

#inviteButtonBox {
  display: flex;
  align-items: center;
}

#inviteButton {
  text-transform: none;
  padding-right: 31px;
  padding-left: 31px;
}

#rowsGridContainer {
  margin-top: 90px;
  margin-bottom: 30px;
}

#rowGridItem {
  display: flex;
  justify-content: center;
}

#rowMainBox {
  display: flex;
  justify-content: center;
  width: 55vw;
  background-color: #f8f9ff;
  padding-top: 15px;
  padding-bottom: 15px;
}

#emailBox {
  width: 15vw;
  display: flex;
  align-items: center;
}

#rowRadioBox {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 40px;
}

#rowDelInviteBox {
  display: flex;
  align-items: center;
  margin-left: 40px;
}
