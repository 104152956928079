#topHeadTypo {
  color: #5f5e5e;
}

#topTypoBox {
  width: 100%;
}

#topTypo {
  color: #868686;
  font-size: 13px;
}

#textFieldButtonFlex {
  width: 100%;
  display: flex;
  margin-top: 2rem;
}

#textFieldBox {
  width: 80%;
}

#buttonMainBox {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#topButtonBox {
  display: flex;
  flex-direction: column;
}

#topSubBox {
  margin-right: 8px;
  margin-left: 8px;
  height: 100%;
}

#downloadTempButton {
  /* color: #071aff; */
  font-size: 0.9vw;
  width: 100%;
  text-transform: none;
  border: 1px solid #e1e2e9;
  font-weight: bold;
}

#uploadFileBox {
  margin-right: 8px;
  margin-left: 8px;
  height: 100%;
  margin-top: 4px;
}

#uploadFileButton {
  /* color: #071aff; */
  font-size: 0.9vw;
  width: 100%;
  text-transform: none;
  border: 1px solid #e1e2e9;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
}

#uploadIcon {
  margin-right: 5px;
}

#doneButtonBox {
  display: flex;
  justify-content: flex-end;
  margin: 0px 8px;
}

#doneButton {
  text-transform: none;
  padding: 4px 30px;
}

#backButtonBox {
  border: 1px solid #ffffff;
}
