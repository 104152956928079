#mainBox {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 97vh; */
  flex-direction: column;
  width: 100%;
  /* border: 1px solid red; */
  /* height: 100%;
  height: -moz-available; 
  height: -webkit-fill-available; 
  height: fill-available; */
}

#presentationContentBox {
  width: 100%;
  border: 3px solid #071aff;
  height: 74vh;
  border-radius: 5px;
  margin-top: 3vh;
  position: relative;
  background-color: #ffffff;
}

#leftContentBoxIcon {
  width: 38px;
  height: 52px;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 34vh;
  left: -15px;
  z-index: 100;
  background-color: #ffffff;
  border-radius: 3px;
  cursor: pointer;
}

#rightContentBoxIcon {
  width: 38px;
  height: 52px;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 34vh;
  right: -15px;
  z-index: 100;
  background-color: #ffffff;
  border-radius: 3px;
  cursor: pointer;
}

#navBarBox {
  width: 100%;
  height: 4vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 8px;
}

#navBoxBottom {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

#bottomNavSubBox {
  width: 7vw;
  height: 30px;
  border: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#bottomLeftIconBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 50%;
  background-color: #868686;
  cursor: pointer;
}

#ChevronLeftIcon {
  font-size: 19px;
  color: white;
}

#slideNoTypo {
  font-size: 11px;
  color: #393939;
  font-weight: bold;
}

#bottomRightIconBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 50%;
  background-color: #868686;
  cursor: pointer;
}

#ChevronRightIcon {
  font-size: 19px;
  color: white;
}

#logoBox {
  position: absolute;
  width: 190px;
  height: 50px;
  right: 0px;
  cursor: pointer;
  display: flex;
  /* border: 1px solid red; */
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

#logoImg {
  width: 87px;
  height: 50px;
}

#marginBox {
  margin: 0px 23px 0px 23px;
}

/* ------------------------------- */
