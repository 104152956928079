/* #pricingComponentMainGrid {
  height: 100%;
  width: 1px;
  overflow-y: auto;
  overflow-x: auto;
  padding: 30px 50px 0px 50px;
} */
#pricingComponentMainGridLoading {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: auto;
  padding: 30px 50px 0px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#pricingComponentMainGrid {
  overflow-y: auto;
  height: -webkit-fill-available;
  padding: 0px 60px 0px;
}
#pricingComponentMainGrid::-webkit-scrollbar {
  width: 5px;
}
#pricingComponentMainGrid::-webkit-scrollbar-track {
  background: #f1f1f1;
}
#pricingComponentMainGrid::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 100px;
}

/* ::-webkit-scrollbar {
  width: 2px;
  border-radius: 7px;

}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 7px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 7px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
} */



#topNavMainBox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

#individualNavMainBox {
  display: flex;
  width: 20%;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
}
