#img {
  width: 420px;
  height: 260px;
  padding: 20px;
  box-shadow: 0px 3px 6px #00000029;
}
#griditem1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}
#griditem2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
}
#forgottext {
  font-size: 12px;
  color: #071aff;
  text-transform: capitalize;
  font-weight: bold;
  text-decoration: none;
}
#loginbtn {
  width: 240px;
  height: 38px;
  border-radius: 10px;
  background-color: #071aff;
  margin-left: 19%;
  margin-top: 10px;
  color: white;
  text-transform: capitalize;
}

#signupwithgooglebutton {
  background: #f8f9ff;
  border: 1px solid #cccccc;
  text-transform: none;
  color: #947070;
  font-size: 13px;
  font-weight: 400;
  width: 240px;
  height: 38px;
  border-radius: 5px;
}

#googlebuttonicon {
  width: 16px;
  height: 16px;
  margin: 10px;
  margin-right: 20px;
}

#divider {
  height: 320px;
  margin-left: 10%;
  border-color: #dfd7d7;
}

/* collaboration login */

#welcometypo {
  color: #5f5e5e;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 4vh;
}

#invitetypo {
  color: #5f5e5e;
  font-size: 14px;
  text-align: center;
}

#highlightedtext {
  color: #333441;
  font-weight: bold;
}

#emailtypo {
  color: #5f5e5e;
  font-size: 14px;
  margin: 10px 0px;
}

#gridcontainer {
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10px;
}

#containertypo {
  color: #5f5e5e;
  font-size: 12px;
  font-weight: bold;
}

#getstartedbtn {
  text-transform: none;
  border-radius: 4px;
  margin: 10px 0px;
}

#mainbox {
  width: 300px;
  text-align: center;
  margin-top: 10px;
}

#boxtypo {
  font-size: 9px;
  color: #5f5e5e;
}

#link {
  color: #071aff;
  text-decoration: underline;
  font-weight: bold;
}
