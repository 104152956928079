#pricingMainGrid {
  overflow-y: auto;
  height: -webkit-fill-available;
  padding: 0px 60px 0px;
}
#pricingMainGrid::-webkit-scrollbar {
  width: 5px;
}
#pricingMainGrid::-webkit-scrollbar-track {
  background: #f1f1f1;
}
#pricingMainGrid::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 100px;
}

#pricingMantraMainBox {
  width: 100%;
  display: flex;
}

#pricingMantraTypoBox {
  width: 20%;
}

#pricingMantraTypo {
  color: #5f5e5e;
  font-weight: bold;
}

#mantraDescriptionBox {
  width: 80%;
}

#mantraDescriptionTypo {
  font-size: 12px;
  color: #868686;
}

#buttonBox {
  margin-top: 10px;
}

#addRevenueStreamButton {
  font-size: 14px;
  text-transform: none;
  text-decoration: underline;
}

#nextButtonBox {
  border: 1px solid #ffffff;
}
