/* #tableCell {
  border-right: 1px solid #00000029;
} */

#tableCellName {
  display: flex;
  align-items: center;
  /* border-right: 1px solid #00000029; */
  justify-content: center;
}

#tableHeadText {
  font-size: 12px;
  font-weight: bold;
  color: #868686;
}

#tableStyle {
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;

  /* // sx={{
      //   // border: "1px solid #ebe0df",
      //   // borderTop: "1px solid #ebe0df",
      //   borderRadius: "20px",
      //   // boxShadow: 3,
      //   boxShadow: "0px 3px 6px #00000029",
  
      //   // [`& .${tableCellClasses.root}`]: {
      //   //   borderBottom: "none",
      //   // },
      // }} */
}

#addMemberButton {
  background-color: #ffffff;
  /* margin-top: 10px; */
  text-transform: none;
  /* border: 1px solid #071aff; */
  color: #071aff;
  font-weight: bold;
  margin-left: 20px;
  font-size: 10px;
}

#disabledaddMemberButton {
  background-color: #ffffff;
  /* margin-top: 10px; */
  text-transform: none;
  /* border: 1px solid #071aff; */
  color: #b0b0b0;
  font-weight: bold;
  margin-left: 20px;
  font-size: 10px;
}

#delMemberButton {
  background-color: #ffffff;
  margin-top: 10px;
  text-transform: none;
  border: 1px solid #ff7f5c;
  color: #ff7f5c;
  font-weight: bold;
  margin-left: 20px;
  font-size: 10px;
}

#disableddelMemberButton {
  background-color: #ffffff;
  margin-top: 10px;
  text-transform: none;
  border: 1px solid #b0b0b0;
  color: #b0b0b0;
  font-weight: bold;
  margin-left: 20px;
  font-size: 10px;
  cursor: default;
}

#tableButtonBox {
  margin-bottom: 10px;
  margin-top: 20px;
}

#teamMainGrid {
  height: 100%;
  width: 1px;
  overflow-y: auto;
  overflow-x: auto;
  padding: 40px 50px;
}

#graphicalViewHead {
  font-size: 14px;
  margin-bottom: 20px;
  margin-top: 20px;
  color: #707070;
}

#teamTableBox {
  /* margin-bottom: 20px; */
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  margin-top: 20px;
}
