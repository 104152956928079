#maingrid {
  overflow-y: auto;
  height: 100%;
  width: 100%;
  padding: 0px 60px 0px;
  padding-bottom: 0px;
}
#maingrid::-webkit-scrollbar {
  width: 5px;
}
#maingrid::-webkit-scrollbar-track {
  background: #f1f1f1;
}
#maingrid::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 100px;
}

#btn {
  margin-right: 38px;
  border-radius: 8px;
  text-transform: capitalize;
}

#introgriditem {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

#imggrid {
  display: flex;
  align-items: center;
}

#imgbox {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

#img {
  box-shadow: 0 4px 12px rgb(0 0 0 / 15%);
  height: 40vh;
  margin: 0 auto;
  padding: 50px 0px;
}

/* concept vision details  */
#highlightofficon {
  fill: grey;
}

/* Next Page  */
#griditem {
  display: flex;
  flex-direction: column;
}

#nextgrid {
  justify-content: space-between;
  margin: 30px 0px;
}

/* Finish Page */
#finishimg {
  width: 300px;
  height: 300px;
}

#finishgriditem {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#finishbtn {
  background-color: rgb(7, 26, 255);
  border-radius: 8px;
  text-transform: capitalize;
}

#individualbox {
  margin-top: 15px;
}

@media (min-width: 1281px) {
  /* #textboxgrid {
    margin-bottom: 30px;
  } */
  /* body {
    background: skyblue !important;
  } */
}

@media (min-height: 600px) and (max-height: 767px) {
  /* body {
    background: lightyellow !important;
  } */
  #individualbox:first-child {
    margin-top: 0px;
  }
  #nextgrid {
    margin: 20px 0px;
  }

  #maingrid {
    padding: 15px 60px 0px;
    padding-bottom: 0px;
  }
}
