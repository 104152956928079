#topTypo {
  color: #5f5e5e;
}

#subTypo {
  font-size: 13px;
  color: #868686;
}

#subTypo2 {
  font-size: 13px;
  color: #868686;
  margin-top: 20px;
}

#anchorTableMainBox {
  width: 100%;
  margin-top: 20px;
}

#tableHeadBox {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

#tableHeadTypoBox {
  width: 27%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

#tableHeadTypo {
  color: #5f5e5e;
  font-size: 14px;
  font-weight: bold;
}

#dividerBox {
  margin-top: 10px;
}

#divider {
  color: #b1aaaa;
}

#addButtonBox {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  /* background-color: #071aff; */
  margin-top: 10px;
  cursor: pointer;
}

#addIcon {
  font-size: 22px;
  color: #ffffff;
}

#bottomTypoTextfieldBox {
  width: 100%;
  margin-top: 20px;
}

#bottomTypo {
  color: #5f5e5e;
  margin-bottom: 10px;
}

#backNextButton {
  border: 1px solid #ffffff;
}
