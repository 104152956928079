#mainGrid {
  overflow-y: auto;
  height: -webkit-fill-available;
}

#progressContentBox {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
  /* justify-content: space-evenly; */
  padding: 0px 60px 0px;
  row-gap: 1em;
}

#imagesMainBox {
  display: flex;
  width: 90%;
  /* height: 25%; */
  justify-content: center;
}

#positioningBlue1 {
  width: 84px;
  height: 84px;
  margin: 0px 3px;
}

#positioning1 {
  width: 90px;
}

#dashBorderBox {
  width: 25%;
}

#dashBorderTypoBox {
  height: 30%;
  display: flex;
  justify-content: center;
}

#dashBorderTypo {
  color: #393939;
  font-weight: bold;
}

#dashBorder {
  border-top: dashed 2px gray;
  position: relative;
  top: 16%;
}

#arrowIcon {
  position: absolute;
  bottom: -24px;
  color: blue;
  font-size: 50px;
  z-index: 100;
  left: 38%;
}

#rightArrowIcon {
  position: absolute;
  bottom: -24px;
  color: blue;
  font-size: 50px;
  z-index: 100;
  right: 37%;
}

#textContentBox {
  display: flex;
  width: 90%;

  margin-top: 20px;

  justify-content: space-evenly;
}

#textContentBoxTop {
  display: flex;
  width: 90%;

  margin-bottom: 20px;

  margin-top: 30px;
}

#textTypoBox {
  width: 33%;
  display: flex;
  justify-content: center;
}

#textTypoBoxBottom {
  /* width: 33%; */
  width: 28%;
  display: flex;
  justify-content: center;

  text-align: center;
}

#textTypo {
  font-size: 16px;
  color: #393939;
  font-weight: bold;
}

#textTypoBottom {
  font-size: 14px;
  color: #393939;
}

#midTextContentBox {
  width: 33%;
  display: flex;
  justify-content: center;

  text-align: center;
}

#midTextTypo {
  font-size: 16px;
  color: #393939;
  font-weight: bold;
}

#midTextTypoBottom {
  font-size: 14px;
  color: #393939;
}

#backNextBox {
  width: 90%;
  margin-left: 5%;
}

#sectionHeaderBox {
  width: 100%;
  height: 7%;
  margin: 30px 60px 0px;
}
