#milestoneMainBox {
  width: 100%;
  /* height: 35vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 180px;
  position: relative;
}

#borderBox {
  border: 1px solid #838383;
  width: 100%;
  position: relative;
}

#milestoneFlex {
  position: absolute;
  top: -19px;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  /* border: 1px solid red; */
  /* position: relative; */
}

/* individual milestone */

#individualMilestoneBox {
  display: flex;
  flex-direction: column;
  width: 18.7%;
  align-items: center;
}

#milestoneNoBox {
  width: 36px;
  height: 36px;
  border: 1px solid #919aff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

#milestoneNoTypo {
  color: #393939;
  font-weight: bold;
}

#milestoneTitle {
  color: #393939;
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 2.1vh;
}

#textFieldBox {
  width: 100%;
}
