#mainGrid {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  height: 100%;
}

#contentBox {
  display: flex;
  align-items: center;
  flex-direction: column;
}

#image {
  width: 150px;
  height: 150px;
  margin: 10px 0px;
}

#button {
  cursor: pointer;
  color: #071aff;
  margin-top: 10px;
  text-transform: lowercase;
  font-weight: bold;
}
