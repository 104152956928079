#mainTitleBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#titleBox {
  width: 80%;
}

#titleTypo {
  font-size: 38px;
  color: #393939;
  font-weight: bold;
}

#headerIconMainBox {
  display: flex;
}

#iconBox {
  height: 62px;
  width: 62px;
  border: 2px solid #b4b4fa;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#commentIconBox {
  margin-left: 10px;
}
