#partnershipTypo {
  color: #5f5e5e;
  font-size: 16px;
  font-weight: bold;
}

#flexImgTypoBox {
  width: 100%;
  display: flex;
}

#topTypoBox {
  width: 65%;
}

#topTypo {
  color: #868686;
  font-size: 12px;
}

#imgBox {
  width: 35%;
  /* border: 1px solid red; */
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  position: relative;
}

#tableMainBox {
  width: 100%;
  margin-top: 20px;
}

#tableHeadBox {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

#tableHeadTypoBox {
  width: 20%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

#tableHeadTypo {
  font-size: 14px;
  color: #5f5e5e;
}

#dividerBox {
  margin-top: 10px;
}

#divider {
  color: #b1aaaa;
}

#addButtonBox {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-top: 10px;
  cursor: pointer;
}

#addIcon {
  font-size: 22px;
  color: #ffffff;
}

#backNextBox {
  margin-top: 10px;
  border: 1px solid #ffffff;
}

#imgSubBox {
  position: absolute;
  left: 115px;
  top: -43px;
}

#img {
  width: 128px;
  height: 124px;
}
