#barblue {
  height: 4px;
  width: 100px;
  border-radius: 50px;
  background-color: blue;
  margin: 5px 20px 5px 0px;
}
#bar {
  height: 4px;
  width: 100px;
  border-radius: 50px;
  background-color: #b1aaaa;
  margin: 5px 20px 5px 0px;
}
#boxtext {
  border: 1px solid dimgray;
  border-radius: 10px;
  width: 70%;
  padding: 10px 20px;
  height: 10vh;
  resize: none;
}
#box1 {
  display: flex;
}
#box2 {
  display: flex;
  justify-content: space-between;
}
#typography1 {
  display: inline-flex;
  align-self: center;
}
#typography2 {
  font-size: 12px;
  margin-bottom: 20px;
}
#typography3 {
  font-size: 13px;
  width: 25%;
  margin-right: 2%;
}
#nextbtn {
  text-transform: none;
  font-weight: bold;
  margin-right: 30px;
  float: right;
  color: #071aff;
}

/* FInal PAGE CSS */
#finalgrid {
  box-shadow: 0px 0px 2px #00000029;
  border: 1px solid #70707074;
  border-radius: 5px;
  margin-right: 5%;
  margin-bottom: 1%;
  height: 15vh;
}
#finaltypography {
  background-color: #f0f0f0;
  border: 1px solid #70707072;
  border-radius: 5px;
  height: 5vh;
  padding: 0px 10px;
  color: #393939;
  font-size: 12px;
}
#finaltextarea {
  resize: none;
  width: -webkit-fill-available;
  border: 1px solid #70707074;
  height: 8.4vh;
  box-shadow: 0px 0px 2px #00000029;
  border-top: none;
}

#marketMainGrid {
  padding: 5px 60px;
}

#mapGrid {
  height: 65%;
}

#finalMapGrid {
  column-gap: 96px;
}

#othertitles {
  flex: 0 0 70%;
  text-align: center;
}

#questionsContainer {
  display: flex;
  flex-direction: column;
  row-gap: 2em;
  height: 100%;
}

@media (max-width: 1024px) {
  #typography3 {
    /* width: 100%; */
    width: 25%;
  }
  #marketMainGrid {
    padding: 5px 60px;
    height: 100%;
    overflow-y: auto;
  }

  #finalMapGrid {
    column-gap: 63px;
  }
}

@media (min-height: 600px) and (max-height: 690px) {
  #finaltextarea {
    height: 8vh;
  }
}
