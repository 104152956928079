#headTypo {
  color: #5f5e5e;
  font-weight: bold;
}

#multiSelectMainBox {
  width: 100%;
  margin-top: 4vh;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 5%;
}

#multiBox {
  height: 49px;
  width: 20%;
  border: 1px solid #fdd3a4;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

#multiBoxBottom {
  height: 49px;
  width: 20%;
  border: 1px solid #fdd3a4;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  margin-top: 4vh;
}

#otherChannelsMainBox {
  width: 100%;
  margin-top: 5vh;
  display: flex;
  justify-content: space-between;
}

#otherChannelsTypoBox {
  width: 20%;
  display: flex;
  justify-content: center;
}

#otherChannelsTypo {
  font-size: 14px;
  color: #5f5e5e;
  margin-top: 2px;
}

#textFieldBox {
  width: 75%;
}

#multiBoxTypo {
  color: #5f5e5e;
  font-size: 14px;
  font-weight: bold;
}
