#barblue {
  height: 4px;
  width: 100px;
  border-radius: 50px;
  background-color: blue;
  margin: 5px 20px 5px 0px;
}
#bar {
  height: 4px;
  width: 100px;
  border-radius: 50px;
  background-color: #b1aaaa;
  margin: 5px 20px 5px 0px;
}
#boxtext {
  border: 1px solid dimgray;
  border-radius: 10px;
  width: 70%;
  padding: 10px 20px;
  height: 10vh;
  resize: none;
}

#box1 {
  display: flex;
}
#box2 {
  display: flex;
  justify-content: space-between;
}
#typography1 {
  display: inline-flex;
  align-self: center;
}
#typography2 {
  font-size: 12px;
  margin-bottom: 10px;
}
#typography3 {
  font-size: 13px;
  width: 20%;
  margin-right: 10px;
}
#maingrid {
  overflow-y: auto;
  height: -webkit-fill-available;
  padding: 0px 60px 0px;
}
#maingrid::-webkit-scrollbar {
  width: 5px;
}
#maingrid::-webkit-scrollbar-track {
  background: #f1f1f1;
}
#maingrid::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 100px;
}
#gridcontainer {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  margin-bottom: 10px;
  padding: 12px 0px;
}
#firsttitle {
  flex: 0 0 200px;
  position: sticky;
  left: 0;
  background-color: white;
  z-index: 5;
}
#othertitles {
  flex: 0 0 300px;
  text-align: center;
}
#firstcolumn {
  flex: 0 0 200px;
  position: sticky;
  left: 0;
  background-color: white;
  z-index: 5;
}

#columntypography {
  font-size: 14px;
  width: 150px;
  white-space: pre-wrap;
}
