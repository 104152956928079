#titleBox {
  display: flex;
  justify-content: space-between;
}

#titleTypo {
  font-size: 11px;
  font-weight: bold;
  margin: 3px 0px 0px 5px;
}

#iconBox {
  position: absolute;
  right: 5px;
  top: 3px;
  height: 18px;
  z-index: 110;
}

#icon {
  color: #393939;
}

#visibilityLayoverBox {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 0.7;
  background-color: #ffffff;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

#visibilityIcon {
  opacity: 0.7;
  font-size: 40px;
  color: gray;
}
