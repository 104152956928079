#tractionTitleBox {
  height: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

#tractionTitleSubBox {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  position: relative;
}

#tractionTitleColorBox {
  width: 25%;
  height: 63px;
  background-color: #b4b4fa;
  border-radius: 4px;
  position: relative;
}

#tractionTitleWhiteBox {
  width: 100%;
  height: 63px;
  background-color: white;
  border-radius: 4px;
  position: absolute;
  border: 1px solid #b4b4fa;
  right: 6px;
  bottom: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

#tractionTitleTypo {
  color: #393939;
  font-weight: bold;
  font-size: 2.3vh;
  text-align: center;
}

#dividerBox {
  margin-top: 2vh;
  display: flex;
  justify-content: center;
}

#divider {
  border-color: #b1aaaa;
  width: 40%;
}

#CommentHeadBox {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1vh;
}

#commentHeadTypo {
  font-size: 2.3vh;
  font-weight: bold;
  color: #393939;
}

#commentMainBox {
  width: 100%;
  height: 36vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
}

#individualCommentBox {
  width: 23%;
  height: 35vh;
  border: 1px solid #b4b4fa;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 4px;
  position: relative;
}

#commentContentBox {
  width: 97%;
  text-align: center;
}

#commentContent {
  font-size: 1.8vh;
  color: #707070;
}

#divider {
  margin-top: 5px;
  display: flex;
  border-bottom: 1px solid #b1aaaa;
  width: 30%;
}
