#mainBox {
  display: flex;
  justify-content: space-between;
  height: 72vh;
  /* border: 1px solid red; */
  overflow: auto;
}

#leftContentBox {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 3vh;
  margin-bottom: 3vh;
}

/* #editIconBox {
  width: 80px;
  height: 80px;
  border: 2px dotted #b4b4fa;
  border-radius: 10px;
  margin-left: 5px;
  position: relative;
  cursor: pointer; 
} */

#iconBox {
  width: 80px;
  height: 80px;
  border: 2px solid #b4b4fa;
  border-radius: 10px;
  /* box-shadow: 0px 3px 14px #00000029; */
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#ventureBox {
  padding-left: 5px;
}

#ventureTypo {
  font-size: 40px;
  color: #393939;
}

#productTypoBox {
  width: 100%;
  margin-top: 1vh;
  margin-bottom: 3.5vh;
  /* border: 1px solid red; */
}

#productTypo {
  font-size: 22px;
  color: #393939;
}

#imgBox {
  /* width: 343px; */
  width: 25vw;
  border: 1px solid #b4b4fa;
  margin-right: 10px;
  border-radius: 5px;
  background-color: #ffffff;
  /* background-color: #b4b4fa; */
  margin-top: 3vh;
  margin-bottom: 3vh;
  /* height: 441px; */
  height: 65.9vh;
  position: relative;
}

#editIconBox {
  width: 80px;
  height: 80px;
  border: 2px dotted #b4b4fa;
  border-radius: 10px;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

#editIcon {
  font-size: 15px;
  color: #707070;
}

#iconSubBox {
  position: absolute;
  right: 1px;
  top: 1px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #ffffff;
  opacity: 0.5;
  /* border-radius: 5px; */
}

#subIconBox {
  width: 90%;
  text-align: center;
}

#logoBoxTypo {
  font-size: 10px;
  font-weight: bold;
}

#replaceImgBox {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 50%;
  border-radius: 5px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
}
