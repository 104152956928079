.menuContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu {
  background: #ffffff !important;
  border-radius: 5px;
  position: absolute;
  top: 60px;
  right: 30px;
  /* width: 261px; */
  width: 250px;
  box-shadow: 0px 7px 20px #00000029;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
  z-index: 5000;
}

.menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

#profileImg {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  /* border: solid 1px #707070; */
  box-shadow: 0px 3px 6px #071aff15;
}

/* .menu.inactive {
  opacity: 0;
  visibility: none;
  transform: translateY(0);
} */

.myProjects {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.iconImage {
  height: 24px;
  width: 24px;
  margin: 13px 20px;
}

#myProTitleTypo {
  margin-left: 10px;
  font-weight: bold;
  color: #707070;
  font-size: 15px;
}

.titleBox {
  display: flex;
  align-items: center;
  cursor: pointer;
}

#myAccountTypo {
  margin-left: 10px;
  font-weight: bold;
  color: #707070;
  font-size: 15px;
}

#feedbackTypo {
  margin-left: 10px;
  font-weight: bold;
  color: #707070;
  font-size: 15px;
}

#supportTypo {
  margin-left: 10px;
  font-weight: bold;
  color: #707070;
  font-size: 15px;
}

#logoutTypo {
  margin-left: 10px;
  font-weight: bold;
  color: #707070;
  font-size: 15px;
}

#contentBox {
  display: flex;
  flex-direction: column;
}
